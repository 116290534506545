import React from 'react';
import { DemoRequestsUIProvider } from './DemoRequestsUIProvider';
import { DemoRequestsCard } from './DemoRequestsCard';
import { useDispatch } from 'react-redux';

export function DemoRequestsPage({history}) {
  const dispatch = useDispatch();

  const demoRequestUIEvents = {
    /*openViewDemoDialog: (id) => {
      history.push(`${cadsAiDemoRequestsBaseUrl}/${id}/view`);
    },*/
  };

  return (
      <DemoRequestsUIProvider demoRequestUIEvents={demoRequestUIEvents}>
        {/*<Switch>
        <Route path={`${cadsAiDemoRequestsBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <DemoRequestViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setDemoRequestFilters());
                history.push(cadsAiDemoRequestsBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>*/}
        <DemoRequestsCard/>
      </DemoRequestsUIProvider>
  );
}
