import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import DemoRequestsTable from './DemoRequestsTable';

const DemoRequestsTableWrapper = () => {

  return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="products-list">
            <div className="card__title">
              <h5 className="bold-text">Demo Requests List</h5>
            </div>
            <DemoRequestsTable/>
          </CardBody>
        </Card>
      </Col>
  );
};

export default DemoRequestsTableWrapper;
