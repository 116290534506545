import authReducer from '@/containers/App/Account/Auth/redux/reducer';
import skillsReducer from '@/containers/Admin/SkillBank/Skills/redux/reducer';
import subCategoriesReducer from '@/containers/Admin/SkillBank/SubCategories/redux/reducer';
import domainsReducer from '@/containers/Admin/SkillBank/Domains/redux/reducer';
import questionsReducer from '@/containers/Admin/SkillBank/Questions/redux/reducer';
import customerReducer from '@/containers/Admin/Customers/redux/reducer';
import assessmentReducer from '@/containers/Admin/Assessments/redux/reducer';
import assignmentReducer from '@/containers/Admin/Assignments/redux/reducer';
import tagReducer from '@/containers/Admin/Tags/redux/reducer';
import GeneralAssessmentConfigReducer from '@/containers/Admin/Assessments/components/assessment-config-card/general-assessment-config/redux/reducer';

export const AdminReducers = {
  user: authReducer,
  skills: skillsReducer,
  questions: questionsReducer,
  customers: customerReducer,
  assessments: assessmentReducer,
  assignments: assignmentReducer,
  tags: tagReducer,
  assessmentConfig: GeneralAssessmentConfigReducer,
  subCategories: subCategoriesReducer,
  domains: domainsReducer,
};
