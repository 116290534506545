import React, { useEffect, useState } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import { Button, ButtonToolbar } from 'reactstrap';
import { YesNoOptions } from '@/shared/helpers';
import FormField from '@/shared/components/form/hook/FormField';
import { useForm } from 'react-hook-form';
import { SelectField } from "@/shared/components/form/Select";
import { RadioButtonField } from "@/shared/components/form/RadioButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCustomersListOptions } from "@/containers/Admin/Customers/redux/actions";
import { getAssessmentListOptions } from "@/containers/Admin/Assessments/redux/actions";
import moment from "moment";

const AssignmentEditDialogBody = ({modal, isEdit, id}) => {

  const dispatch = useDispatch();
  const initialValues = {
    organization: {value: '', label: 'All'},
    assessment: {value: '', label: 'All'},
    expiryDate: ''
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: {errors},
  } = useForm({defaultValues: initialValues});

  const [data, setData] = useState(initialValues);
  const [isReAssignable, setIsReAssignable] = React.useState(`${YesNoOptions[1].value}`);
  const [state, setState] = useState({
    loading: false,
    newAssignmentAdded: false,
    errorMessage: '',
  });

  useEffect(() => {
    if (isEdit && id) {
      setDefaultData(id);
    }
  }, [isEdit, id]);

  const setDefaultData = async (id) => {
    try {
      const {data: res, isSuccess} = await api.assignments.getAssignmentById(id);
      const assignmentData = res.data;
      if (id) {
        const data = {
          id,
          organization: organizationAllOpts.filter(org => org.id === assignmentData.organizationId).map(org => ({
            value: org.id,
            label: org.title
          }))?.[0],
          assessment: assessmentAllOpts.filter(assessment => assessment.id === assignmentData.assessmentId)?.map(assessment => ({
            value: assessment.id,
            label: assessment.title
          }))?.[0],
          expiryDate: assignmentData.expiryDate ? moment(assignmentData.expiryDate).format('YYYY-MM-DD') : ''
        };
        setData({...data});
        reset({...data});
      }
    } catch ({message}) {
      console.error('Assignment edit error : ', message);
      setState({
        ...state,
        errorMessage: 'Error while getting existing assignment details',
      });
    }
  };

  const constructApiRequestData = async ({id, organization, assessment, expiryDate}) => {
    return {
      id: isEdit ? +id : null,
      organizationId: organization.value,
      assessmentId: assessment.value,
      expireDate: expiryDate ? moment(expiryDate).format('YYYY-MM-DD') : '',
      isReAssignable: isReAssignable === 'true'
    };
  };
  const handleAddEditAssignment = async (assignmentData) => {
    setState({...state, loading: true});
    const newAssignmentData = await constructApiRequestData(assignmentData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} assignment`;
    try {
      const {data: res, isSuccess} = isEdit
          ? await api.assignments.editAssignment(newAssignmentData)
          : await api.assignments.addAssignment(newAssignmentData);
      if (isSuccess) {
        setState({...state, newAssignmentAdded: true, loading: false});
        if (!isEdit) {
          setData(initialValues);
          reset(initialValues);
        }
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newAssignmentAdded: false,
        errorMessage: error.message,
      });
    }
  };

  /*Populate organization options*/
  const organizationAllOpts = useSelector((state) => state.customers?.options, shallowEqual);
  const [organizationOptions, setOrganizationOptions] = React.useState({value: null, label: 'All'});
  React.useEffect(() => {
    dispatch(getCustomersListOptions());
  }, []);
  React.useEffect(() => {
    setOrganizationOptions([
      {value: null, label: 'All'}, //adding default select all option
      ...(organizationAllOpts?.map((org) => ({
        value: org.id,
        label: org.title,
      })) ?? []),
    ]);
  }, [organizationAllOpts]);

  /*Populate assessment options*/
  const assessmentAllOpts = useSelector((state) => state.assessments?.options, shallowEqual);
  const [assessmentOptions, setAssessmentOptions] = React.useState({value: null, label: 'All'});
  React.useEffect(() => {
    dispatch(getAssessmentListOptions());
  }, []);
  React.useEffect(() => {
    setAssessmentOptions([
      {value: null, label: 'All'}, //adding default select all option
      ...(assessmentAllOpts?.map((assessment) => ({
        value: assessment.id,
        label: assessment.title,
      })) ?? []),
    ]);
  }, [assessmentAllOpts]);


  return (
      <div>
        {/*Add/Edit Assignment Form Status*/}
        <div className="ml-4">
          {state.loading && (
              <div>
                <div className="panel__refresh position-relative" style={{height: 75, width: 20}}>
                  <LoadingIcon/>
                  <div style={{padding: '23px 46px', width: 'max-content'}}>
                    {isEdit ? 'Updating' : 'Adding new'} assignment...
                  </div>
                </div>
              </div>
          )}
          {(state.newAssignmentAdded || !!state.errorMessage) && (
              <>
                <table className="mt-3">
                  <tr>
                    <td>
                      {state.newAssignmentAdded ? (
                          <span className="lnr lnr-thumbs-up modal__title-icon success"/>
                      ) : (
                          <span className="lnr lnr-cross-circle modal__title-icon error"/>
                      )}
                    </td>
                    <td className="pl-2">
                      {state.newAssignmentAdded
                          ? `${isEdit ? 'Assignment Updated' : 'New Assignment Added'} Successfully`
                          : state.errorMessage}
                    </td>
                  </tr>
                </table>
              </>
          )}
        </div>
        <div className="modal__body add-edit-learning-path dropzone-border">
          <form className={`form`} style={{gap: 10}} onSubmit={handleSubmit(handleAddEditAssignment)}>
            <div className="form__form-group">
              <span className="form__form-group-label">Organization</span>
              <div className="form__form-group-field">
                <FormField
                    control={control}
                    name="organization"
                    component={SelectField}
                    options={organizationOptions}
                    placeholder="Select Organization To Assign"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Assessment</span>
              <div className="form__form-group-field">
                <FormField
                    control={control}
                    name="assessment"
                    component={SelectField}
                    options={assessmentOptions}
                    placeholder="Select Assessment To Assign"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Expiry Date</span>
              <div className="form__form-group-field">
                <FormField
                    name="expiryDate"
                    type="date"
                    placeholder="Enter End Date"
                    control={control}
                    defaultValue=""
                />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group">
                <span className="form__form-group-label">Is Re-Assignable?</span>
                <div className="form__form-group-field mt-2">
                  <RadioButtonField
                      radioValue={`${YesNoOptions[0].value}`}
                      name={YesNoOptions[0].label}
                      onChange={setIsReAssignable}
                      value={isReAssignable}
                      className="colored-click mr-5"
                      label={YesNoOptions[0].label}
                  />
                  <RadioButtonField
                      radioValue={`${YesNoOptions[1].value}`}
                      name={YesNoOptions[1].label}
                      onChange={setIsReAssignable}
                      value={isReAssignable}
                      className="colored-click mr-5"
                      label={YesNoOptions[1].label}
                  />
                </div>
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar modal__footer">
              <Button disabled={state.loading} color="primary" type="submit">
                Submit
              </Button>
              <Button type="button" className="modal_cancel" onClick={modal.hide}>
                Close
              </Button>
            </ButtonToolbar>
          </form>
        </div>
      </div>
  );
};

export default AssignmentEditDialogBody;
