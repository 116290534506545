import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { renderComponentField } from '@/shared/components/form/FormField';

const DropZoneField = ({value, customHeight, name, onChange, acceptOnly}) => {
  const files = value;
  const maxSize = 2 * 1048576; // 2MB
  const onDrop = (file) => {
    onChange(
        file.map((fl) =>
            Object.assign(fl, {
              preview: URL.createObjectURL(fl),
            }),
        ),
    );
  };
  const removeFile = (index, e) => {
    e.preventDefault();
    onChange(value.filter((val, i) => i !== index));
  };

  return (
    <div className={`dropzone dropzone--single${customHeight ? ' dropzone--custom-height' : ''}`}>
      <Dropzone
          accept={acceptOnly || "image/jpeg, image/png, image/gif, image/png, image/gif"}
          name={name}
          multiple={false}
          onDrop={(fileToUpload) => {
          onDrop(fileToUpload);
        }}
          maxSize={maxSize}
      >
        {({ getRootProps, getInputProps, fileRejections }) => {
          const isFileTooLarge =
            fileRejections.length > 0 && fileRejections[0]?.file.size > maxSize;
          return (
            <div {...getRootProps()} className="dropzone__input">
              {(!files || files.length === 0) && (
                <div className="dropzone__drop-here">
                  <span className="lnr lnr-upload" /> Drop file here to upload
                  {isFileTooLarge && (
                    <div className="text-danger text-center mt-2">File is too large.</div>
                  )}
                </div>
              )}
              <input {...getInputProps()} />
            </div>
          );
        }}
      </Dropzone>
      {files && Array.isArray(files) && files.length > 0 && (
        <aside className="dropzone__img">
          <img src={files[0]?.preview} alt="drop-img" />
          <p className="dropzone__img-name">{files[0]?.name}</p>
          <button className="dropzone__img-delete" type="button" onClick={(e) => removeFile(0, e)}>
            Remove
          </button>
        </aside>
      )}
    </div>
  );
};

DropZoneField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  customHeight: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
  ]).isRequired,
};

DropZoneField.defaultProps = {
  customHeight: false,
};

export default renderComponentField(DropZoneField);
