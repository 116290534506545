import React, { useRef } from 'react';
import { Button } from 'reactstrap';
import Select from 'react-select';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getQuestionLevelsList, getQuestionTagsList, setQuestionFilters } from '../../redux/actions';
import { getSkillsList } from '@/containers/Admin/SkillBank/Skills/redux/actions';
import { useLocation } from "react-router-dom";

export function QuestionTableSearchBar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const {tagId: tagIdFromLocation} = location?.state ?? {};
  const [tagId, setTagId] = React.useState(null);
  const {data: {length: totalQuestionCount}, questionTags: tags} = useSelector((state) => state.questions, shallowEqual);
  const {data: skills} = useSelector((state) => state.skills, shallowEqual);
  const {questionLevels: levels} = useSelector((state) => state.questions, shallowEqual);

  /*Default Options*/
  const defaultSkillsOptions = [{value: null, label: 'All Skills'}];
  const defaultLevelOptions = [{value: null, label: 'All Levels'}];

  const [isSearched, setIsSearched] = React.useState(false);
  const [searchTags, setSearchTags] = React.useState([]);
  const [searchSkill, setSearchSkill] = React.useState(defaultSkillsOptions);
  const [searchLevel, setSearchLevel] = React.useState(defaultLevelOptions);

  /* Get skills options */
  const [skillsOptions, setSkillsOptions] = React.useState(defaultSkillsOptions);

  const getSkillsOptions = React.useCallback(() => {
    //TODO: implement proper solution by fetching all skills or by passing search text
    dispatch(getSkillsList({pageSize: 1000, pageNumber: 1})); // assuming there won't be more than 100 skills
  }, []);

  React.useEffect(() => {
    setSkillsOptions([
      defaultSkillsOptions[0],
      ...(skills?.map((skill) => ({ value: skill.id, label: skill.name })) ?? []),
    ]);
  }, [skills]);

  /* Get questions level options */
  const [levelOptions, setLevelOptions] = React.useState(defaultLevelOptions);

  const getLevelsOptions = React.useCallback(() => {
    dispatch(getQuestionLevelsList());
  }, []);

  React.useEffect(() => {
    setLevelOptions([
      defaultLevelOptions[0],
      ...(levels?.map((level) => ({value: level.id, label: level.name})) ?? []),
    ]);
  }, [levels]);

  /* Get question tags*/
  const [tagOptions, setTagOptions] = React.useState({value: null, label: 'All'});
  const getTagsOptions = React.useCallback(() => {
    dispatch(getQuestionTagsList());
  }, []);

  React.useEffect(() => {
    if (tagIdFromLocation) {
      setTagId(tagIdFromLocation);
    }
  }, [tagIdFromLocation]);

  /*Set tag options*/
  React.useEffect(() => {
    const options = tags?.map((tag) => ({value: tag.id, label: tag.name}));
    setTagOptions(options ?? []);
    if (tagId) {
      setSearchTags(options?.filter(tag => tag.value === tagId));
    }
  }, [tags, tagId]);

  React.useEffect(() => {
    getSkillsOptions();
    getLevelsOptions();
    getTagsOptions();
  }, []);


  const searchNameRef = useRef(null);

  const handleSearch = () => {
    setIsSearched(true);
    dispatch(
        setQuestionFilters({
          questionText: searchNameRef?.current?.value,
          skillId: searchSkill.value,
          questionLevelId: searchLevel.value,
          pageNumber: 1,
          tags: searchTags.map(tag => tag.value).join(','),
        }),
    );
    setTagId(null);
  };

  const handleClear = () => {
    if (searchNameRef?.current?.value) searchNameRef.current.value = '';
    setIsSearched(false);
    dispatch(setQuestionFilters({search: '', status: null, tags: ''}));
    setSearchTags([]);
    setSearchSkill(defaultSkillsOptions);
    setSearchLevel(defaultLevelOptions);
  };

  return (
    <TableHead>
      <div className="table__search">
        <div className="table__search_item">
          <b>Question Text</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchNameRef}
                  style={{ height: 36, width: 350 }}
                  name="searchText"
                  type="text"
                  placeholder="Search by Question Text"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item">
          <b>Skill</b>
          <div style={{ width: 200 }}>
            <Select
                name="skill"
                value={searchSkill}
                onChange={setSearchSkill}
                autosize={true}
                options={skillsOptions}
                clearable={false}
                className="react-select"
                placeholder={'Select Skill'}
                classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item">
          <b>Tags</b>
          <div style={{maxWidth: 310, minWidth: 200}}>
            <Select
                name="skill"
                value={searchTags}
                onChange={setSearchTags}
                autosize={true}
                isMulti={true}
                isClearable={true}
                options={tagOptions}
                className="react-select"
                placeholder={'Select Tags'}
                classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item">
          <b>Question Level</b>
          <div style={{width: 150}}>
            <Select
                name="questionLevel"
                value={searchLevel}
                onChange={setSearchLevel}
                autosize={true}
                options={levelOptions}
                clearable={false}
              className="react-select"
              placeholder={'Select Skill'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalQuestionCount} matches</span>
        </div>
      )}
    </TableHead>
  );
}
