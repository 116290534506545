import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchAssessmentList = createAction('FETCH_ASSESSMENT_LIST');
export const setAssessmentList = createAction('SET_ASSESSMENT_LIST');
export const setAssessmentError = createAction('SET_ASSESSMENT_ERROR');
export const setAssessmentFilters = createAction('SET_ASSESSMENT_FILTERS');
export const deleteAssessmentFromList = createAction('DELETE_ASSESSMENT_FROM_LIST');
export const setAssessmentOptionsList = createAction('SET_ASSESSMENT_OPTIONS_LIST');
export const setAssessmentCategoryOptionsList = createAction(
    'SET_ASSESSMENT_CATEGORY_OPTIONS_LIST',
);
export const setAssessmentTypeOptionsList = createAction('SET_ASSESSMENT_TYPE_OPTIONS_LIST');
export const setAssessmentDataSkillOptionsList = createAction(
  'SET_ASSESSMENT_DATA_SKILL_OPTIONS_LIST',
);


export const getAssessmentsList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting assessments list';
  try {
    dispatch(fetchAssessmentList());
    const { data, isSuccess } = await api.assessments.getAssessmentList(filter);
    if (isSuccess) dispatch(setAssessmentList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setAssessmentError(error));
    console.error(defaultError, error.message);
  }
};

export const getAssessmentCategoryOptionsList = () => async (dispatch) => {
  const defaultError = 'Error occurred getting assessment category options list';
  try {
    const { data, isSuccess } = await api.assessments.getAssessmentCategoryOptions();
    if (isSuccess) dispatch(setAssessmentCategoryOptionsList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};
export const getAssessmentTypeOptionsList = () => async (dispatch) => {
  const defaultError = 'Error occurred getting assessment type options list';
  try {
    const { data, isSuccess } = await api.assessments.getAssessmentTypeOptions();
    if (isSuccess) dispatch(setAssessmentTypeOptionsList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const getAssessmentDataSkillOptions = () => async (dispatch) => {
  const defaultError = 'Error occurred getting assessment data skill options list';
  try {
    const { data, isSuccess } = await api.assessments.getAssessmentDataSkillOptions();
    if (isSuccess) dispatch(setAssessmentDataSkillOptionsList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};

export const deleteAssessment = (assessmentId) => (dispatch) => {
  try {
    dispatch(deleteAssessmentFromList(assessmentId));
  } catch (error) {
    console.error('Unable to delete remove assessment from assessment list');
  }
};

export const getAssessmentListOptions = () => async (dispatch) => {
  const defaultError = 'Error occurred getting assessment options list';
  try {
    const {data, isSuccess} = await api.assessments.getAssessmentOptionsList();
    if (isSuccess) dispatch(setAssessmentOptionsList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    console.error(defaultError, error.message);
  }
};
