import React, { createContext, useContext } from 'react';

const TagUIContext = createContext();

export function useTagUIContext() {
  return useContext(TagUIContext);
}

export function TagUIProvider({tagUIEvents, children}) {
  const value = {
    openQuestionListPage: tagUIEvents.openQuestionListPage,
    openAssessmentListPage: tagUIEvents.openAssessmentListPage
  };

  return <TagUIContext.Provider value={value}>{children}</TagUIContext.Provider>;
}
