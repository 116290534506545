import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import DemoRequestsTableWrapper from './table/DemoRequestsTableWrapper';

export function DemoRequestsCard() {
  return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Demo Requests</h3>
          </Col>
        </Row>
        <Row>
          <DemoRequestsTableWrapper/>
        </Row>
      </Container>
  );
}
