import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import api from '@/utils/apiClient';
import { useForm } from 'react-hook-form';
import KnowledgeBaseThresholdFields from './KnowledgeBaseThresholdFields';
import KnowledgeBaseQuestionFieldTable, { L1_PREFIX, L2_PREFIX, PS_PREFIX, } from './KnowledgeBaseQuestionFieldTable';
import KnowledgeBaseAssessmentConfigFormStatus from './KnowledgeBaseAssessmentConfigFormStatus';

const KnowledgeBaseAssessmentConfigForm = ({ assessmentId, backToListView }) => {
  //states
  const [state, setState] = React.useState({
    loading: false,
    fetchingConfig: false,
    isConfigUpdated: false,
    errorMessage: '',
    forTags: null
  });
  const [questionSkills, setQuestionSkills] = React.useState([]);

  React.useEffect(() => {
    const tags = new URLSearchParams(window.location.search).get('tags');
    if (tags) {
      setState({...state, forTags: true});
    } else {
      setState({...state, forTags: false});
    }
  }, []);

  // prefill the form
  React.useEffect(() => {
    if (assessmentId && state.forTags !== null) {
      setDefaultData(+assessmentId);
    }
  }, [assessmentId, state.forTags]);

  const setDefaultData = async (assessmentId) => {
    try {
      let res, isSuccess;
      if (state.forTags) {
        const {data, isSuccess: success} = await api.assessments.getTagsConfigsById(assessmentId);
        data.data.question.skill = data.data.question.tag.map(tag => ({...tag, skillId: tag.tagId, skillName: tag.tagName}))
        res = data;
        isSuccess = success;
      } else {
        let {data, isSuccess: success} = await api.assessments.getConfigsById(assessmentId);
        res = data;
        isSuccess = success;
      }
      if (isSuccess) {
        ['timeLimit', 'scoreToQualify'].forEach((field) => setValue(field, res?.data?.[field]));
        setQuestionSkills(res?.data?.question?.skill);
      } else {
        throw new Error(res?.messages?.[0]);
      }
    } catch (e) {
      console.error('Config edit error : ', e);
      setState({...state, errorMessage: 'Error while getting existing config details'});
    }
  };

  // config form related functions
  const {
    handleSubmit,
    reset,
    control,
    trigger,
    setValue,
    formState: { errors },
  } = useForm();

  const resetForm = () => {
    reset({ timeLimit: 0, scoreToQualify: 0 });
  };

  React.useEffect(() => {
    questionSkills?.forEach((skill) => {
      setValue(`${L1_PREFIX}${skill.skillId}`, skill.level1);
      setValue(`${L2_PREFIX}${skill.skillId}`, skill.level2);
      setValue(`${PS_PREFIX}${skill.skillId}`, skill.passingScore);
      trigger(); //async validating form values
    });
  }, [questionSkills]);

  const onSubmit = async ({ timeLimit, scoreToQualify }) => {
    setState({ ...state, loading: true });
    const defaultError = `Error occurred while updating configuration`;
    try {
      let data, isSuccess;
      if (state.forTags) {
        const {data: d, isSuccess: s} = await api.assessments.editTagConfigs({
          assessmentId,
          timeLimit: +timeLimit,
          scoreToQualify: +scoreToQualify,
          question: {tag: questionSkills.map(skill => ({...skill, tagId: skill.skillId, tagName: skill.skillName}))},
        });
        data = d;
        isSuccess = s;
      } else {
        const {data: d, isSuccess: s} = await api.assessments.editConfigs({
          assessmentId,
          timeLimit: +timeLimit,
          scoreToQualify: +scoreToQualify,
          question: {skill: questionSkills},
        });
        data = d;
        isSuccess = s;
      }
      if (isSuccess) {
        setState({...state, isConfigUpdated: true, loading: false});
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({ ...state, loading: false, isConfigUpdated: false, errorMessage: error.message });
    }
  };

  const onCancel = () => {
    resetForm();
  };

  const formFiledProps = {
    control,
    errors,
  };

  return (
    <div>
      {/*Add/Edit Config Form*/}
      <form className={`form`} onSubmit={handleSubmit(onSubmit)}>
        <KnowledgeBaseThresholdFields {...formFiledProps} />
        <KnowledgeBaseQuestionFieldTable
            {...formFiledProps}
            forTags={state.forTags}
            skills={questionSkills}
            setSkills={setQuestionSkills}
        />
        <KnowledgeBaseAssessmentConfigFormStatus formState={state} />
        <ButtonToolbar className="form__button-toolbar modal__footer">
          <Button disabled={state.loading} color="primary" type="submit">
            Submit
          </Button>
          <Button type="button" color="secondary" onClick={backToListView}>
            Back To Assessment List
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default KnowledgeBaseAssessmentConfigForm;
