import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Button } from 'reactstrap';
import PencilIcon from 'mdi-react/PencilIcon';
// import BinIcon from 'mdi-react/BinIcon';
import { useAssignmentUIContext } from '../AssignmentUIProvider';
import AssignmentTableColumns, { AssignmentColumns } from './AssignmentTableColumns';
import { AssignmentTableSearchBar } from './AssignmentTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAssignmentsList, setAssignmentFilters } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import moment from "moment";

const AssignmentTable = () => {
  const assignmentUIContext = useAssignmentUIContext();
  const dispatch = useDispatch();
  const {
    data: assignments,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.assignments, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  React.useEffect(() => {
    dispatch(getAssignmentsList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
        setAssignmentFilters({sortBy: property, isSortOrderDescending: !isSortOrderDescending}),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setAssignmentFilters({pageSize: rowsPerPage, pageNumber: currentPage + 1}));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setAssignmentFilters({pageSize: +event.target.value, pageNumber: 1}));
  };

  const emptyRows =
      page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - assignments.length : 0;

  const renderActionButtons = ({id: assignmentId}) => (
      <div className="actions">
        {/*      <Button
        className="icon mb-0"
        color="success"
        outline
        onClick={() => assignmentUIContext.openViewAssignmentDialog(assignmentId)}
      >
        <p>
          <EyeIcon /> View
        </p>
      </Button>*/}
        <Button
            className="icon mb-0"
            color="primary"
            outline
            onClick={() => assignmentUIContext.openEditAssignmentDialog(assignmentId)}
        >
          <p>
            <PencilIcon/> Edit
          </p>
        </Button>
        {/*            <Button
                className="icon mb-0"
                color="danger" outline
                onClick={()=>assignmentUIContext.openDeleteAssignmentDialog(assignmentId)}
            >
                <p><BinIcon /> Delete</p>
            </Button>*/}
      </div>
  );

  return (
      <Fragment>
        <div className="material-table__wrap">
          <AssignmentTableSearchBar/>
          <Table className="material-table">
            <AssignmentTableColumns
                order={isSortOrderDescending ? 'desc' : 'asc'}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rowsPerPage}
            />
            <TableBody>
              {(loading || !!error) && (
                  <TableRow>
                    <TableCell colSpan={AssignmentColumns.length}>
                      {loading && (
                          <div className={'text-center overflow-hidden'}>
                            <div className="panel__refresh position-relative" style={{height: 75}}>
                              <LoadingIcon/>
                            </div>
                          </div>
                      )}
                      <div className={'text-center'}>
                        <p className={!!error ? 'm-5' : ''}>{error || 'Loading assignments...'}</p>
                      </div>
                    </TableCell>
                  </TableRow>
              )}
              {!!!assignments.length && !loading && !error && (
                  <TableRow style={{height: 120}}>
                    <TableCell colSpan={AssignmentColumns.length} align="center">
                      {filters.status === null && filters.search === ''
                          ? 'No Assignments'
                          : 'No Assignments with applied filters'}
                    </TableCell>
                  </TableRow>
              )}
              {assignments.map((assignment) => {
                return (
                    <TableRow className="material-table" tabIndex={-1} key={assignment?.organizationId}>
                      <TableCell
                          className="material-table__cell"
                          component="th"
                          align="center"
                          scope="row"
                      >
                        {assignment?.organizationName}
                      </TableCell>
                      <TableCell align="center" className="material-table__cell">
                        {assignment?.assessmentTitle}
                      </TableCell>
                      <TableCell align="center" className="material-table__cell">
                        {assignment?.expiryDate ? moment(assignment?.expiryDate)?.format('yyyy-MM-DD') : null}
                      </TableCell>
                      <TableCell align="center" className="material-table__cell">
                        {assignment?.createdDate ? moment(assignment?.createdDate)?.format('yyyy-MM-DD') : null}
                      </TableCell>
                      <TableCell
                          align="center"
                          className="material-table__cell"
                          style={{minWidth: '295px'}}
                      >
                        {renderActionButtons(assignment)}
                      </TableCell>
                    </TableRow>
                );
              })}
              {emptyRows > 0 && (
                  <TableRow style={{height: 49 * emptyRows}}>
                    <TableCell colSpan={AssignmentColumns.length}/>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            component={defaultPaginationProps.component}
            backIconButtonProps={defaultPaginationProps.backIconButton}
            nextIconButtonProps={defaultPaginationProps.nextIconButton}
            rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
            className={defaultPaginationProps.className}
            dir={defaultPaginationProps.dir}
        />
      </Fragment>
  );
};

export default AssignmentTable;
