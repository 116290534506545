import React, { useEffect, useMemo, useState } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import { Button, ButtonToolbar } from 'reactstrap';
import { emptyStringPattern } from '@/shared/helpers';
import FormField from '@/shared/components/form/hook/FormField';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getOrganizationOptionsList } from '../../../redux/organization/actions';
import { SelectField } from '@/shared/components/form/Select';
import { RadioButtonField } from "@/shared/components/form/RadioButton";
import renderDropZoneField from "@/shared/components/form/DropZone";

const DdoEditDialogBody = ({ modal, isEdit, id }) => {
  const initialValues = {
    ddoProgramTitle: '',
    organization: '',
    ddoPlanMaxUsersCount: '',
    ddoStartdate: '',
    ddoEnddate: '',
  };

  const dispatch = useDispatch()
  const { options: organizationOpts } = useSelector((state) => state.cadsAIOrgs, shallowEqual);
  const [data, setData] = useState(initialValues);
  const [status, setStatus] = React.useState(true);
  const [organizationOptions, setOrganizationOptions] = useState([]);

  const [state, setState] = useState({
    loading: false,
    newDdoAdded: false,
    errorMessage: '',
  });

  useEffect(() => {
    dispatch(getOrganizationOptionsList());
  }, []);

  useEffect(() => {
    if (isEdit && id) {
      setDefaultData(id);
    }
  }, [isEdit, id]);

  useMemo(() => {
    setOrganizationOptions(
      organizationOpts?.map((org) => ({ value: org.id, label: org.title })) ?? [],
    );
  }, [organizationOpts]);

  const setDefaultData = async (id) => {
    try {
      const { data: res } = await api.cadsAIDdo.getDdoById(id);
      const ddoData = res.data;
      if (id) {
        setData({
          ...ddoData,
        });
        ['ddoProgramTitle', 'description', 'ddoPlanMaxUsersCount', 'ddoPlanId'].forEach((field) =>
            setValue(field, ddoData?.[field]),
        );
        setStatus(ddoData.status);
        setValue('ddoStartdate', ddoData?.ddoStartdate?.substr(0, 10) ?? '')
        setValue('ddoEnddate', ddoData?.ddoEnddate?.substr(0, 10) ?? '')
        setValue(
            'organization',
            !ddoData ? null : {value: ddoData?.organization?.id, label: ddoData?.organization?.title},
        );
      }
    } catch ({ message }) {
      console.error('DDO edit error : ', message);
      setState({
        ...state,
        errorMessage: 'Error while getting existing ddo details',
      });
    }
  };
  /*
  * {
    "ddoPlanId": "",
    "isPurchased": true,
    "ddoReportName": "string",
    "ddoReportFile": "",
    "purchaseDate": "2022-12-15T13:15:23.773Z",
    "financePaymentId": 0,
    "isActivatedByAdmin": true,
    "activatedByAdminDate": "2022-12-15T13:15:23.773Z",
    "activatedByAdminUser": "string",
    "activatedByAdminDescription": "string"
  }
  * */
const constructApiRequestData = async ({
                                         id,
                                         ddoProgramTitle,
                                         organization,
                                         ddoPlanMaxUsersCount,
                                         ddoStartdate,
                                         ddoEnddate,
                                         ddoReportFile,
                                         description,
                                       }) => {
    return {
      id,
      organizationId: organization?.value ?? '',
      ddoStartdate,
      ddoEnddate,
      ddoReportFile,
      ddoProgramTitle,
      ddoPlanMaxUsersCount,
      description,
    };
  };
  const handleAddEditDdo = async (ddoData) => {
    setState({ ...state, loading: true });
    const newDdoData = await constructApiRequestData(ddoData);
    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} ddo`;
    try {
      const { data: res, isSuccess } = isEdit
        ? await api.cadsAIDdo.updateDdo(newDdoData)
        : await api.cadsAIDdo.addDdo(newDdoData);
      if (isSuccess) {
        setState({ ...state, newDdoAdded: true, loading: false });
        if (!isEdit) setData(initialValues);
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newDdoAdded: false,
        errorMessage: error.message,
      });
    }
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: {errors},
  } = useForm({defaultValues: initialValues});

  return (
      <div>
        {/*Add/Edit Ddo Form Status*/}
        <div className="ml-4">
          {state.loading && (
              <div>
                <div className="panel__refresh position-relative" style={{height: 75, width: 20}}>
                  <LoadingIcon/>
                  <div style={{width: 200, padding: '23px 30px'}}>
                {isEdit ? 'Updating' : 'Adding new'} ddo...
              </div>
            </div>
          </div>
        )}
        {(state.newDdoAdded || !!state.errorMessage) && (
          <>
            <table className="mt-3">
              <tr>
                <td>
                  {state.newDdoAdded ? (
                    <span className="lnr lnr-thumbs-up modal__title-icon success" />
                  ) : (
                    <span className="lnr lnr-cross-circle modal__title-icon error" />
                  )}
                </td>
                <td className="pl-2">
                  {state.newDdoAdded
                    ? `${isEdit ? 'DDO Updated' : 'New DDO Added'} Successfully`
                    : state.errorMessage}
                </td>
              </tr>
            </table>
          </>
        )}
      </div>
      <div className="modal__body add-edit-learning-path dropzone-border">
        <form className={`form form--horizontal`} onSubmit={handleSubmit(handleAddEditDdo)}>
          {!state.newDdoAdded ? (
            <div className={'w-100 p-3'}>
              <div className="form__form-group">
                <span className="form__form-group-label">Program Title</span>
                <div className="form__form-group-field">
                  <FormField
                      name="ddoProgramTitle"
                      control={control}
                      component="input"
                      errors={errors}
                      rules={{
                        required: 'This is required field',
                        pattern: emptyStringPattern,
                      }}
                      defaultValue=""
                      isAboveError={true}
                      placeholder="Enter Program Title"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Description</span>
                <div className="form__form-group-field">
                  <FormField
                      name="description"
                      control={control}
                      component="textarea"
                      defaultValue=""
                      placeholder="Enter Program Description"
                  />
                </div>
              </div>
              <div className="form__form-group mt-3">
                <span className="form__form-group-label">Organization</span>
                <div className="form__form-group-field">
                  <FormField
                      control={control}
                      name="organization"
                      component={SelectField}
                    options={organizationOptions}
                    placeholder="Select Organization"
                    errors={errors}
                    clearable={true}
                    isAboveError
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Start Date</span>
                <div className="form__form-group-field">
                  <FormField
                      name="ddoStartdate"
                      type="date"
                      placeholder="Enter Start Date"
                      control={control}
                      defaultValue=""
                      isAboveError
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">End Date</span>
                <div className="form__form-group-field">
                  <FormField
                      name="ddoEnddate"
                      type="date"
                      placeholder="Enter End Date"
                      control={control}
                      defaultValue=""
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Max Participants</span>
                <div className="form__form-group-field">
                  <FormField
                      name="ddoPlanMaxUsersCount"
                      control={control}
                      type="number"
                      min={1}
                      component="input"
                      errors={errors}
                      rules={{
                        required: 'This is required field',
                        pattern: emptyStringPattern,
                      }}
                      defaultValue=""
                      isAboveError={true}
                      placeholder="Enter Max Participants"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label w-25">Report File</span>
                <span className="form__form-group-label text-right w-75">
                  only PDF accepted, max size: 2MB
                </span>
                <div className="form__form-group-field" style={{marginLeft: 120}}>
                  <FormField name="ddoReportFile" control={control} component={renderDropZoneField} acceptOnly="application/pdf"/>
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Status</span>
                <div className="form__form-group-field mt-2">
                  <RadioButtonField
                      radioValue={`true`}
                      name={'status'}
                      onChange={() => setStatus(true)}
                      value={`${status}`}
                      className="colored-click mr-5"
                      label={'Active'}
                  />
                  <RadioButtonField
                      radioValue={`false`}
                      name={'status'}
                      onChange={() => setStatus(false)}
                      value={`${status}`}
                      className="colored-click mr-5"
                      label={'Deactivate'}
                  />
                </div>
              </div>
              <ButtonToolbar className="modal__footer btn-toolbar">
                <>
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                  <Button
                      color="secondary"
                      type="button"
                      className="modal_cancel btn btn-secondary"
                      onClick={() => modal.hide()}
                  >
                    Close
                  </Button>
                </>
              </ButtonToolbar>
            </div>
          ) : (
            <div className={'p-3'}>
              <ButtonToolbar className="modal__footer btn-toolbar">
                <Button color="primary" type="button" onClick={() => modal.hide()}>
                  Okay
                </Button>
              </ButtonToolbar>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default DdoEditDialogBody;
