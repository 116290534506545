import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const demoRequestBaseUrl =
    process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_BASE_INFO_ENDPOINT;

export const demoRequestEndpoints = {
  GET_ALL_DEMO_REQUESTS: '/',
};

const getDemoRequestList = (filter) => {
  return getMockDemoRequestList();
  return sendRequest({
    fullUrl: demoRequestEndpoints.GET_ALL_DEMO_REQUESTS,
    method: httpMethod.GET,
    params: filter,
  });
};

const getMockDemoRequestList = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
        () =>
            resolve({
              isSuccess: true,
              data: {
                status: 200,
                messages: [],
                data: {
                  totalRecords: 3,
                  records: [
                    {
                      id: 1,
                      fullName: 'Mock Full Name 1',
                      email: 'mock-demo-1@email.com',
                      contactNumber: '+1-1234567890',
                      requestDate: '2022-04-13T16:14:20.753Z',
                    }, {
                      id: 2,
                      fullName: 'Mock Full Name 2',
                      email: 'mock-demo-2email.com',
                      contactNumber: '+91-5645785689',
                      requestDate: '2022-04-17T16:14:20.753Z',
                    }, {
                      id: 3,
                      fullName: 'Mock Full Name 3',
                      email: 'mock-demo-3@email.com',
                      contactNumber: '+65-568569659',
                      requestDate: '2022-04-17T16:14:20.753Z',
                    },
                  ],
                },
              },
            }),
        500,
    );
  });
};

export default {
  getDemoRequestList
}
