/*Commons*/
import appConfigApi from './api/appConfigApi';
import authApi from './api/auth/authApi';
import countryApi from './api/common/countryApi';
/*Assessment Admin*/
import customersApi from './api/admin/customersApi';
import questionsApi from './api/admin/questionsApi';
import skillBaseSkillsApi from './api/admin/skillBank/skillBaseSkillsApi';
import skillBankSubCategoryApi from './api/admin/skillBank/skillBankSubCategoryApi';
import skillBankDomainApi from './api/admin/skillBank/skillBankDomainApi';
/* Campaign Api*/
import sunrise25UsersApi from './api/campaign/sunrise25UsersApi';
/*Control Panel Admin*/
import cpUserAPi from './api/controlPanel/userApi';
import organizationApi from './api/controlPanel/organizationApi';
import cohortApi from './api/controlPanel/cohortApi';
import coursesApi from './api/controlPanel/coursesApi';
import modulesApi from './api/controlPanel/moduleApi';
import moduleSchedulesApi from './api/controlPanel/moduleScheduleApi';
import learningPathsApi from './api/controlPanel/learningPathApi';
import trainingProvidersApi from './api/controlPanel/trainingProvidersApi';
import assignmentApi from '@/utils/api/controlPanel/assignmentApi';
import assessmentsApi from '@/utils/api/admin/assessmentsApi';
import assignmentsApi from '@/utils/api/admin/assignmentsApi';
import tagsApi from '@/utils/api/admin/tagsApi';
import baselineThresholdApi from '@/utils/api/controlPanel/baselineThresholdApi';
/*Data Star*/
import dashboardApi from './api/datastar/dashboardApi';
import programsApi from './api/datastar/programsApi';
import applicantsApi from './api/datastar/applicantsApi';
import dsCohortApi from './api/datastar/dsCohortApi';
import cadsAILearningPathApi from './api/cadsAI/cadsAILearningPathApi';
import cadsAIModuleApi from './api/cadsAI/cadsAIModuleApi';
import cadsAICourseApi from './api/cadsAI/cadsAICourseApi';
import cadsAIUserApi from './api/cadsAI/cadsAIUserApi';
import cadsAIOrganizationApi from './api/cadsAI/cadsAIOrganizationApi';
import cadsAICohortApi from './api/cadsAI/cadsAICohortApi';
import cadsAIAssignmentApi from './api/cadsAI/cadsAIAssignmentApi';
import cadsAICountryApi from './api/cadsAI/cadsAICountryApi';
import cadsAIProgramsApi from './api/cadsAI/cadsAIProgramsApi';
import cadsAIUsersApi from './api/cadsAI/cadsAIUsersApi';
import cadsAIAnnouncementApi from './api/cadsAI/cadsAIAnnouncementApi';
import cadsAIApplicantApi from './api/cadsAI/cadsAIApplicantApi';
import cadsAISponsorsApi from './api/cadsAI/cadsAISponsorsApi';
import cadsAIArticlesApi from '@/utils/api/cadsAI/cadsAIArticlesApi';
import cadsAIEventsApi from './api/cadsAI/cadsAIEventsApi';
import cadsAISponsorshipApi from './api/cadsAI/cadsAISponsorshipApi';
import cadsAIQAApi from './api/cadsAI/cadsAIQAApi';
import cadsAIEmailApi from './api/cadsAI/cadsAIEmailApi';
import cadsAIDSCohortApi from './api/cadsAI/cadsAIDSCohortApi';
import cadsAIDSDashboardApi from './api/cadsAI/cadsAIDSDashboardApi';
import cadsAIJobApi from './api/cadsAI/cadsAIJobApi';
import cadsAIDashboardApi from './api/cadsAI/cadsAIDashboardApi';
import cadsAITrainingProvider from './api/cadsAI/cadsAITrainingProvider';
import cadsAINotificationApi from './api/cadsAI/cadsAINotificationApi';
import cadsAIHelpApi from './api/cadsAI/cadsAIHelpApi';
import cadsAISpamRequest from './api/cadsAI/cadsAISpamRequest';
import cadsAIDemoRequest from './api/cadsAI/cadsAIDemoRequest';
import cadsAIEmployeeApi from './api/cadsAI/cadsAIEmployeeApi';
import cadsAIOrderHistoryApi from './api/cadsAI/cadsAIOrderHistoryApi';
import cadsAIDdoApi from './api/cadsAI/cadsAIDdoApi';

const api = {
  /*Commons*/
  appConfig: appConfigApi,
  country: countryApi,
  auth: authApi,

  /*Assessment Admin*/
  dashboard: dashboardApi,
  customers: customersApi,
  assessments: assessmentsApi,
  assignments: assignmentsApi,
  tags: tagsApi,
  questions: questionsApi,
  skills: skillBaseSkillsApi,
  subCategory: skillBankSubCategoryApi,
  domain: skillBankDomainApi,

  /*Campaign Admin*/
  sunrise25Users: sunrise25UsersApi,

  /*Control Panel Admin*/
  cpUser: cpUserAPi,
  organization: organizationApi,
  cohort: cohortApi,
  assigment: assignmentApi,
  courses: coursesApi,
  modules: modulesApi,
  learningPaths: learningPathsApi,
  trainingProviders: trainingProvidersApi,
  moduleSchedules: moduleSchedulesApi,
  baselineThreshold: baselineThresholdApi,

  /*Data Star Panel*/
  programs: programsApi,
  applicants: applicantsApi,
  dsCohort: dsCohortApi,

  /* CadsAI Api*/
  cadsAILearningPathApi: cadsAILearningPathApi,
  cadsAIModule: cadsAIModuleApi,
  cadsAICourse: cadsAICourseApi,
  cadsAIHelp: cadsAIHelpApi,
  cadsAITrainingProvider: cadsAITrainingProvider,
  cadsAIOrganization: cadsAIOrganizationApi,
  cadsAICohort: cadsAICohortApi,
  cadsAIAssignment: cadsAIAssignmentApi,
  cadsAICountry: cadsAICountryApi,
  cadsAIPrograms: cadsAIProgramsApi,
  cadsAIUser: cadsAIUserApi, //TODO: fix this ambiguous naming
  cadsAIUsers: cadsAIUsersApi,
  cadsAIAnnouncements: cadsAIAnnouncementApi,
  cadsAINotification: cadsAINotificationApi,
  cadsAIArticle: cadsAIArticlesApi,
  cadsAIApplicant: cadsAIApplicantApi,
  cadsAISponsors: cadsAISponsorsApi,
  cadsAIEvent: cadsAIEventsApi,
  cadsAISponsorship: cadsAISponsorshipApi,
  cadsAIQA: cadsAIQAApi,
  cadsAIDSDashboard: cadsAIDSDashboardApi,
  cadsAIEmail: cadsAIEmailApi,
  cadsAIDSCohort: cadsAIDSCohortApi,
  cadsAISpamRequest: cadsAISpamRequest,
  cadsAIDemoRequest: cadsAIDemoRequest,
  cadsAIEmployee: cadsAIEmployeeApi,
  cadsAIOrderHistory: cadsAIOrderHistoryApi,
  cadsAIDdo: cadsAIDdoApi,
  /*CadsAI Opportunities*/
  cadsAIJob: cadsAIJobApi,
  /*CadsAI Dashboard*/
  cadsAIDashboard: cadsAIDashboardApi,
};

export default api;
