import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import AssignmentEditForm from './AssignmentEditForm';
import AssignmentEditDialogHeader from './AssignmentEditDialogHeader';

const AssignmentEditDialog = ({show, onHide, id, isEdit, rtl}) => {
  const [modal, setModal] = useState({
    isOpen: show,
    title: `${isEdit ? 'Edit' : 'Add'} Assignment`,
    hide: onHide,
  });

  return (
      <Modal
          isOpen={show}
          toggle={modal.hide}
          modalClassName={`${rtl.direction}-support`}
          className="modal-dialog--primary modal-dialog--header modal-edit-dialog"
      >
        <AssignmentEditDialogHeader modal={modal}/>
        <AssignmentEditForm modal={modal} isEdit={isEdit} id={id}/>
      </Modal>
  );
};

export default connect((state) => ({
  rtl: state.rtl,
}))(AssignmentEditDialog);
