import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Button } from 'reactstrap';
import EyeIcon from "mdi-react/EyeIcon";
import { useTagUIContext } from '../TagUIProvider';
import TagTableColumns, { TagColumns } from './TagTableColumns';
import { TagTableSearchBar } from './TagTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getTagsList, setTagFilters } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';

const TagTable = () => {
  const tagUIContext = useTagUIContext();
  const dispatch = useDispatch();
  const {
    data: tags,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.tags, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  React.useEffect(() => {
    dispatch(getTagsList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
        setTagFilters({sortBy: property, isSortOrderDescending: !isSortOrderDescending}),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setTagFilters({pageSize: rowsPerPage, pageNumber: currentPage + 1}));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setTagFilters({pageSize: +event.target.value, pageNumber: 1}));
  };

  const emptyRows =
      page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - tags.length : 0;

  const renderActionButtons = ({id: tagId}) => (
      <div className="actions">
        <Button
            className="icon mb-0"
            color="success"
            outline
            onClick={() => tagUIContext.openQuestionListPage(tagId)}
        >
          <p>
            <EyeIcon/> View Questions
          </p>
        </Button>
        <Button
            className="icon mb-0"
            color="primary"
            outline
            onClick={() => tagUIContext.openAssessmentListPage(tagId)}
        >
          <p>
            <EyeIcon/> view Assessments
          </p>
        </Button>
      </div>
  );

  return (
      <Fragment>
        <div className="material-table__wrap">
          <TagTableSearchBar/>
          <Table className="material-table">
            <TagTableColumns
                order={isSortOrderDescending ? 'desc' : 'asc'}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rowsPerPage}
            />
            <TableBody>
              {(loading || !!error) && (
                  <TableRow>
                    <TableCell colSpan={TagColumns.length}>
                      {loading && (
                          <div className={'text-center overflow-hidden'}>
                            <div className="panel__refresh position-relative" style={{height: 75}}>
                              <LoadingIcon/>
                            </div>
                          </div>
                      )}
                      <div className={'text-center'}>
                        <p className={!!error ? 'm-5' : ''}>{error || 'Loading tags...'}</p>
                      </div>
                    </TableCell>
                  </TableRow>
              )}
              {!!!tags.length && !loading && !error && (
                  <TableRow style={{height: 120}}>
                    <TableCell colSpan={TagColumns.length} align="center">
                      {filters.status === null && filters.search === ''
                          ? 'No Tags'
                          : 'No Tags with applied filters'}
                    </TableCell>
                  </TableRow>
              )}
              {tags.map((tag) => {
                return (
                    <TableRow className="material-table" tabIndex={-1} key={tag?.id}>
                      <TableCell
                          className="material-table__cell"
                          component="th"
                          align="center"
                          scope="row"
                      >
                        {tag?.name}
                      </TableCell>
                      <TableCell
                          className="material-table__cell"
                          component="th"
                          align="center"
                          scope="row"
                          title={"Click to open question list"}
                          style={{cursor: "pointer"}}
                          onClick={() => tagUIContext.openQuestionListPage(tag?.id)}
                      >
                        {tag?.countQuestions}
                      </TableCell>
                      <TableCell
                          className="material-table__cell"
                          component="th"
                          align="center"
                          scope="row"
                          title={"Click to open assessment list"}
                          style={{cursor: "pointer"}}
                          onClick={() => tagUIContext.openAssessmentListPage(tag?.id)}
                      >
                        {tag?.countAssessments}
                      </TableCell>
                      <TableCell
                          align="center"
                          className="material-table__cell"
                          style={{minWidth: '295px'}}
                      >
                        {renderActionButtons(tag)}
                      </TableCell>
                    </TableRow>
                );
              })}
              {emptyRows > 0 && (
                  <TableRow style={{height: 49 * emptyRows}}>
                    <TableCell colSpan={TagColumns.length}/>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            component={defaultPaginationProps.component}
            backIconButtonProps={defaultPaginationProps.backIconButton}
            nextIconButtonProps={defaultPaginationProps.nextIconButton}
            rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
            className={defaultPaginationProps.className}
            dir={defaultPaginationProps.dir}
        />
      </Fragment>
  );
};

export default TagTable;
