import { handleActions } from 'redux-actions';
import { fetchAssignmentList, setAssignmentError, setAssignmentFilters, setAssignmentList } from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
    {
      [fetchAssignmentList](state) {
        return {
          ...state,
          loading: true,
          data: [],
        };
      },
      [setAssignmentList](state, {payload}) {
        return {
          ...state,
          loading: false,
          data: payload.records,
          totalRecords: payload.totalRecords,
          error: null,
        };
      },
      [setAssignmentError](state, {payload}) {
        return {
          ...state,
          data: [],
          loading: false,
          totalPages: defaultState.totalPages,
          error: payload?.message,
        };
      },
      [setAssignmentFilters](state, {payload}) {
        return {
          ...state,
          filters: {
            ...state.filters,
            ...payload,
          },
        };
      },
    },
    defaultState,
);
