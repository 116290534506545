import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchAssignmentList = createAction('FETCH_ASSIGNMENT_LIST');
export const setAssignmentList = createAction('SET_ASSIGNMENT_LIST');
export const setAssignmentError = createAction('SET_ASSIGNMENT_ERROR');
export const setAssignmentFilters = createAction('SET_ASSIGNMENT_FILTERS');

export const getAssignmentsList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting assignments list';
  try {
    dispatch(fetchAssignmentList());
    const {data: res, isSuccess} = await api.assignments.getAssignmentList(filter);
    if (isSuccess) dispatch(setAssignmentList(res.data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setAssignmentError(error));
    console.error(defaultError, error.message);
  }
};

