import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import TagTable from './TagTable';

const TagTableWrapper = () => {
  return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="products-list">
            <TagTable/>
          </CardBody>
        </Card>
      </Col>
  );
};

export default TagTableWrapper;
