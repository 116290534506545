import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { useAssessmentUIContext } from '../AssessmentUIProvider';
import AssessmentTableColumns, { AssessmentColumns } from './AssessmentTableColumns';
import { AssessmentTableSearchBar } from './AssessmentTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAssessmentsList, setAssessmentFilters } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps, getColorForWeight } from '@/shared/helpers';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from 'mdi-react/MoreVertIcon';
import { useLocation } from "react-router-dom";

const AssessmentTable = () => {
  const assessmentUIContext = useAssessmentUIContext();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    data: assessments,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.assessments, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  React.useEffect(() => {
    const {tagId = null} = location?.state ?? {};
    if (tagId) {
      filters.tags = `${tagId}`;
      // clear the state once filtered by tagId once
      location.state = {};
    }
    dispatch(getAssessmentsList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setAssessmentFilters({ sortBy: property, isSortOrderDescending: !isSortOrderDescending }),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setAssessmentFilters({pageSize: rowsPerPage, pageNumber: currentPage + 1}));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setAssessmentFilters({pageSize: +event.target.value, pageNumber: 1}));
  };

  const emptyRows =
      page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - assessments.length : 0;

  const RenderActionButtons = ({id: assessmentId}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <div>
          <IconButton
              className="material-table__toolbar-button"
              aria-owns={'simple-menu'}
              aria-haspopup="true"
              onClick={handleClick}
          >
            <MoreVertIcon/>
          </IconButton>
          <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className="material-table__filter-menu"
          >
            <MenuItem onClick={() => assessmentUIContext.openAssessmentConfigureDialog(assessmentId)}
                      className="material-table__filter-menu-item">Configure Skills</MenuItem>
            <MenuItem onClick={() => assessmentUIContext.openAssessmentConfigureDialog(assessmentId, true)}
                      className="material-table__filter-menu-item">Configure Tags</MenuItem>
            <MenuItem onClick={() => assessmentUIContext.openAssessmentDuplicateDialog(assessmentId)}
                      className="material-table__filter-menu-item">Duplicate</MenuItem>
            <MenuItem onClick={() => assessmentUIContext.openViewAssessmentDialog(assessmentId)}
                      className="material-table__filter-menu-item">View</MenuItem>
            <MenuItem onClick={() => assessmentUIContext.openEditAssessmentDialog(assessmentId)}
                      className="material-table__filter-menu-item">Edit</MenuItem>
            <MenuItem onClick={() => assessmentUIContext.openDeleteAssessmentDialog(assessmentId)}
                      className="material-table__filter-menu-item">Delete</MenuItem>
          </Menu>
        </div>
    );
  };

  return (
      <Fragment>
        <div className="material-table__wrap">
          <AssessmentTableSearchBar/>
          <Table className="material-table">
            <AssessmentTableColumns
                order={isSortOrderDescending ? 'desc' : 'asc'}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={AssessmentColumns.length}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading assessments...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!assessments.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={AssessmentColumns.length} align="center">
                  {filters.status === null && filters.search === ''
                    ? 'No Assessments'
                    : 'No Assessments with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {assessments.map((assessment) => {
              return (
                <TableRow className="material-table" tabIndex={-1} key={assessment?.id}>
                  <TableCell
                    className="material-table__cell"
                    component="th"
                    align="center"
                    scope="row"
                  >
                    {assessment.title}
                  </TableCell>
                  <TableCell
                      className="material-table__cell"
                      component="th"
                      align="center"
                      scope="row"
                  >
                    {assessment.categoryName}
                  </TableCell>
                  <TableCell
                      className="material-table__cell"
                      component="th"
                      align="center"
                      scope="row"
                  >
                    {assessment?.tags === "" && "-"}
                    {assessment?.tags !== "" && assessment.tags?.split(',').map((tag) => (
                        <button
                            className={`btn btn-${getColorForWeight({random: true, cssClass: true})} btn-sm d-block`}
                        >
                          {tag}
                        </button>
                    ))}
                  </TableCell>
                  <TableCell
                      className="material-table__cell"
                      component="th"
                      align="center"
                      scope="row"
                  >
                    {assessment.assessmentTypeName}
                  </TableCell>
                  <TableCell
                      className="material-table__cell"
                      component="th"
                      align="center"
                      scope="row"
                  >
                    {assessment.customerName}
                  </TableCell>

                  <TableCell
                    align="center"
                    className="material-table__cell"
                  >
                    <RenderActionButtons id={assessment.id}/>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={AssessmentColumns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default AssessmentTable;
