import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { DemoRequestsPage } from './components/DemoRequestsPage';

export const cadsAiDemoRequestsBaseUrl = paths.CADS_AI.HOME.DEMO_REQUESTS;

const DemoRequests = () => {
  return (
      <Suspense fallback={<Loading/>}>
        <Switch>
          <Route path={cadsAiDemoRequestsBaseUrl} component={DemoRequestsPage}/>
        </Switch>
      </Suspense>
  );
};

export default DemoRequests;
