import React, { useRef } from 'react';
import { Button } from 'reactstrap';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setTagFilters } from '../../redux/actions';

export function TagTableSearchBar() {
  const dispatch = useDispatch();
  const totalTagCount = useSelector(
      (state) => state.tags?.data?.length,
      shallowEqual,
  );

  const [isSearched, setIsSearched] = React.useState(false);

  const searchNameRef = useRef(null);
  const handleSearch = () => {
    setIsSearched(true);
    dispatch(
        setTagFilters({
          searchText: searchNameRef?.current?.value,
          pageNumber: 1,
        }),
    );
  };

  const handleClear = () => {
    if (searchNameRef?.current?.value) searchNameRef.current.value = '';
    setIsSearched(false);
    dispatch(setTagFilters({searchText: ''}));
  };

  //cleanup
  React.useEffect(() => {
    return () => handleClear();
  }, []);

  return (
      <TableHead>
        <div className="table__search">
          <div className="table__search_item">
            <b>Tag Name</b>
            <div className="form">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <div className="form__form-group-icon" style={{height: 37}}>
                    <MagnifyIcon/>
                  </div>
                  <input
                      ref={searchNameRef}
                      className="search-bar-search-text"
                      name="searchText"
                      type="text"
                      placeholder="Search by Tag Title"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="table__search_item mt-3">
            <Button color="primary" onClick={handleSearch}>
              {' '}
              Search{' '}
            </Button>
          </div>
          <div className="table__search_item mt-3">
            <Button color="secondary" onClick={handleClear} outline>
              Clear
            </Button>
          </div>
        </div>

        {isSearched && (
            <div className="ml-1">
              <span>Found {totalTagCount} matches</span>
            </div>
        )}
      </TableHead>
  );
}
