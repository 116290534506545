import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { TagsPage } from './components/TagPage';

export const tagsBaseUrl = paths.TAGS.BASE;

export default function Tags() {
  return (
      <Suspense fallback={<Loading/>}>
        <Switch>
          <Route path={tagsBaseUrl} component={TagsPage}/>
        </Switch>
      </Suspense>
  );
}
