import { useEffect } from 'react';
import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import map from 'highcharts/modules/map';
import api from '@/utils/apiClient';

map(Highcharts);
exporting(Highcharts);

const AudienceGeo = () => {
  const fetchTopology = async () => {
    try {
      const topology = await api.cadsAIDashboard.getWorldMap();
      initialChart(topology);
    } catch (error) {
      console.error('Error inside dashboard user stats', error.message);
    }
  };
  const initialChart = (topology) => {
    const data = [
      ['nz', 52],
      ['us', 12],
      ['as', 13],
      ['in', 74],
      ['na', 14],
      ['sa', 95],
    ];

    // Create the chart
    Highcharts.mapChart('Audience', {
      chart: {
        map: topology,
      },

      title: {
        text: 'Questions posted based on location',
      },

      credits: {
        enabled: false,
      },

      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'bottom',
        },
      },

      colorAxis: {
        min: 0,
        minColor: '#BADA55',
        maxColor: '#BADA55',
      },

      series: [
        {
          data: data,
          name: 'Visitors',
          states: {
            hover: {
              color: '#BADA55',
            },
          },
        },
      ],
    });
  };

  useEffect(() => {
    fetchTopology();
  }, []);

  return <div id="Audience" className="col-lg-7" />;
};

export default AudienceGeo;
