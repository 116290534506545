import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';
import cadsAIProgramsAPI from "@/utils/api/cadsAI/cadsAIProgramsApi";

const unifiedAPIServiceBase =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_ADMIN_ENDPOINT;

const assessmentsEndpoints = {
  GET_ASSESSMENT_LIST: `${unifiedAPIServiceBase}/getAssessmentList`,
  GET_ASSESSMENT_BY_ID: `${unifiedAPIServiceBase}/getAssessmentByID`,
  ADD_ASSESSMENT: `${unifiedAPIServiceBase}/insertAssessment`,
  UPDATE_ASSESSMENT: `${unifiedAPIServiceBase}/editAssessment`,
  DELETE_ASSESSMENT: `${unifiedAPIServiceBase}/deleteAssessment`,
  DUPLICATE_ASSESSMENT: `${unifiedAPIServiceBase}/duplicateAssessment`,
  GET_ASSESSMENT_CONFIG: `${unifiedAPIServiceBase}/getAssessmentConfiguration`,
  GET_TAGS_ASSESSMENT_CONFIG: `${unifiedAPIServiceBase}/getTagsQuestionsByAssessmentId`,
  GET_GENERAL_ASSESSMENT_CONFIG: `${unifiedAPIServiceBase}/getGeneralAssessment`,
  UPDATE_ASSESSMENT_CONFIG: `${unifiedAPIServiceBase}/updateAssessmentConfiguration`,
  UPDATE_ASSESSMENT_TAG_CONFIG: `${unifiedAPIServiceBase}/saveAssessmentConfigurationTag`,
  UPDATE_GENERAL_ASSESSMENT_CONFIG: `${unifiedAPIServiceBase}/updateGeneralAssessment`,

  GET_ASSESSMENT_CATEGORY_OPTIONS_LIST: `${unifiedAPIServiceBase}/getAssessmentCategoryList`,
  GET_ASSESSMENT_TYPE_OPTIONS_LIST: `${unifiedAPIServiceBase}/getAssessmentTypeList`,
  GET_ASSESSMENT_DATA_SKILL_OPTIONS_LIST: `${unifiedAPIServiceBase}/getallDataSkill`, //TODO: change BE endpoint, replace 'a' with upper case 'A' in 'getall'
  GET_ASSESSMENT_OPTIONS_LIST: `${unifiedAPIServiceBase}/`, //TODO: modify when endpoint is available
};

/**
 * @param filter to filter data based on some parameters
 * @param {string} filter.search to filter assessments by search text
 * @param {string} filter.sortBy to sort based on any field
 * @param {number} filter.pageNumber current page number for which data will be retrieve
 * @param {number} filter.pageSize number of records in each page
 * @param {boolean} filter.status assuming true denotes publish and false denotes false
 * @param {boolean} filter.isSortOrderDescending true if search direction is descending
 **/
const getAssessmentList = (filter) => {
  // return getMockAssessmentList();
  return sendRequest({
    fullUrl: assessmentsEndpoints.GET_ASSESSMENT_LIST,
    method: httpMethod.GET,
    params: { ...filter, searchText: filter?.search },
  });
};

const getAssessmentById = (assessmentId) => {
  // return getMockAssessmentById();
  return sendRequest({
    fullUrl: assessmentsEndpoints.GET_ASSESSMENT_BY_ID,
    method: httpMethod.GET,
    params: { id: assessmentId },
  });
};

const addAssessment = (newAssessment) => {
  // return {isSuccess: true}
  return sendRequest({
    fullUrl: assessmentsEndpoints.ADD_ASSESSMENT,
    method: httpMethod.POST,
    data: newAssessment,
  });
};

const editAssessment = (updatedAssessment) => {
  // return {isSuccess: true}
  return sendRequest({
    fullUrl: assessmentsEndpoints.UPDATE_ASSESSMENT,
    method: httpMethod.PUT,
    data: updatedAssessment,
  });
};

const deleteAssessment = (assessmentId) => {
  // return {isSuccess: true};
  return sendRequest({
    fullUrl: assessmentsEndpoints.DELETE_ASSESSMENT,
    method: httpMethod.DELETE,
    params: { id: assessmentId },
  });
};

const duplicateAssessment = (duplicationData) => {
  // return new Promise((resolve, reject) => setTimeout(()=>resolve({isSuccess: true, data: {data: {assessmentId: 3}}}), 1000));
  return sendRequest({
    fullUrl: assessmentsEndpoints.DUPLICATE_ASSESSMENT,
    method: httpMethod.POST,
    data: duplicationData,
  });
};

const getAssessmentCategoryOptions = () => {
  return sendRequest({
    fullUrl: assessmentsEndpoints.GET_ASSESSMENT_CATEGORY_OPTIONS_LIST,
    method: httpMethod.GET,
  });
};

const getAssessmentTypeOptions = () => {
  return sendRequest({
    fullUrl: assessmentsEndpoints.GET_ASSESSMENT_TYPE_OPTIONS_LIST,
    method: httpMethod.GET,
  });
};

const getAssessmentDataSkillOptions = () => {
  return sendRequest({
    fullUrl: assessmentsEndpoints.GET_ASSESSMENT_DATA_SKILL_OPTIONS_LIST,
    method: httpMethod.GET,
  });
};

const getAssessmentOptionsList = () => {
  // return mockGetAssessmentOptionsList();
  return cadsAIProgramsAPI.getAssessmentOptions();
};

const getConfigsById = (assessmentId) => {
  // return getMockAssessmentConfig();
  return sendRequest({
    fullUrl: assessmentsEndpoints.GET_ASSESSMENT_CONFIG,
    method: httpMethod.GET,
    params: {id: assessmentId},
  });
};

const getTagsConfigsById = (assessmentId) => {
  return sendRequest({
    fullUrl: assessmentsEndpoints.GET_TAGS_ASSESSMENT_CONFIG,
    method: httpMethod.GET,
    params: {id: assessmentId},
  });
};

const getGeneralAssessmentConfig = (assessmentId) => {
  return sendRequest({
    fullUrl: assessmentsEndpoints.GET_GENERAL_ASSESSMENT_CONFIG,
    method: httpMethod.GET,
    params: {assessmentId},
  });
};

const editConfigs = (updatedConfigData) => {
  // return new Promise((resolve, reject) => setTimeout(()=>resolve({isSuccess: true}), 1000));
  return sendRequest({
    fullUrl: assessmentsEndpoints.UPDATE_ASSESSMENT_CONFIG,
    method: httpMethod.PUT,
    data: updatedConfigData,
  });
};

const editTagConfigs = (updatedConfigData) => {
  return sendRequest({
    fullUrl: assessmentsEndpoints.UPDATE_ASSESSMENT_TAG_CONFIG,
    method: httpMethod.PUT,
    data: updatedConfigData,
  });
};

const editGeneralAssessmentConfig = (updatedConfigData) => {
  return sendRequest({
    fullUrl: assessmentsEndpoints.UPDATE_GENERAL_ASSESSMENT_CONFIG,
    method: httpMethod.PUT,
    data: updatedConfigData,
  });
};

const getEmployeeList = (filters) => {
  return getMockEmployeeList();
};

export default {
  getAssessmentList,
  getAssessmentById,
  addAssessment,
  editAssessment,
  deleteAssessment,
  duplicateAssessment,
  getConfigsById,
  getTagsConfigsById,
  getGeneralAssessmentConfig,
  editConfigs,
  editTagConfigs,
  editGeneralAssessmentConfig,

  getAssessmentCategoryOptions,
  getAssessmentTypeOptions,
  getAssessmentDataSkillOptions,
  getAssessmentOptionsList,

  getEmployeeList,
};

const getMockAssessmentList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              totalRecords: 2,
              currentPage: 1,
              pageSize: 10,
              records: [
                {
                  id: 1,
                  title: 'Mock Assessment 1 Title',
                  description: 'Assessment 1 Desc',
                  categoryName: 'CADS MODULE CERTIFICATION',
                  assessmentTypeName: 'Knowledge Based',
                  customerName: 'ACCELTIC Test#1',
                },
                {
                  id: 2,
                  title: 'Mock Assessment 2 Title',
                  description: 'Assessment 2 Desc',
                  categoryName: 'CADS SMART SKILLS',
                  assessmentTypeName: 'General Assessment',
                  customerName: 'b2btesting',
                },
              ],
            },
          },
        }),
      500,
    );
  });

const getMockAssessmentById = () =>
  new Promise((resolve, reject) => {
    setTimeout(() =>
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: [''],
          data: {
            id: 1,
            title: 'Mock Title 1',
            description: 'Mock Title 1',
            assessmentCategory: { id: 1, title: 'Mock Assessment 1' },
            assessmentType: { id: 1, title: 'Mock Assessment Type 1' },
            customer: { id: 1, title: 'Mock customer 1' },
            domain: { id: 1, title: 'Mock Title 1' },
            subcategory: { id: 1, title: 'Mock subcategory 1' },
            introduction:
              '<h1 style="text-align:center;"><strong>🌟INTRODUCTION🌟</strong></h1> <blockquote><strong>Here is some quoted text</strong></blockquote> <ul> <li><strong>This is smaple intro page</strong></li> <li><strong><em>You can also make it italic.</em></strong></li> <li><strong><em>Step 1</em></strong></li> <ul> <li><strong><em>Step 1.1</em></strong></li> <li><strong><em>Step 2.2</em></strong></li> </ul> </ul>',
            thankYouPage: '<h1 style="text-align:center;"><strong>Thank You🎅</strong></h1>',
            status: true, //meaning that assessment is published not sure about status = 'pending configuration',
            associatedSkills: [
              { id: 1, title: 'Mock SKill 1' },
              { id: 2, title: 'Mock skill 2' },
            ],
            associatedDomains: [
              { id: 1, title: 'Mock Domain 1' },
              { id: 2, title: 'Mock domain 2' },
            ],
          },
        },
      }),
    );
  });

const getMockAssessmentConfig = () =>
  new Promise((resolve, reject) => {
    setTimeout(() =>
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: [''],
          data: {
            timeLimit: 60,
            scoreToQualify: 80,
            questions: {
              skill: [
                {
                  skillId: 1,
                  skillName: 'Mock Skill 1',
                  level1: 44,
                  level2: 47,
                  passingScore: 80,
                },
                {
                  skillId: 2,
                  skillName: 'Mock Skill 2',
                  level1: 35,
                  level2: 75,
                  passingScore: 70,
                },
              ],
            },
          },
        },
      }),
    );
  });

const getMockEmployeeList = () =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [''],
            data: {
              totalRecords: 2,
              currentPage: 1,
              pageSize: 10,
              records: [
                {
                  id: 1,
                  name: 'Mock Employee 1',
                  email: 'employee1@mock.com',
                },
                {
                  id: 2,
                  name: 'Mock Employee 2',
                  email: 'employee2@mock.com',
                },
              ],
            },
          },
        }),
        500,
    );
  });

const mockGetAssessmentOptionsList = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: [''],
          data: [
            {id: 24, title: 'Mock - Data Cleansing & Preparation Quiz'},
            {id: 25, title: 'Mock - DBMS Quiz'},
            {id: 26, title: 'Mock - Hadoop Quiz'},
          ]
        }
      })
    }, 500);
  });
}
