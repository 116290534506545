import React, { useRef, useState } from 'react';
import { Button } from 'reactstrap';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setDemoRequestFilters } from '../../redux/actions';

export function DemoRequestsTableSearchBar() {
  const dispatch = useDispatch();
  const totalDemoRequestCount = useSelector((state) => state.cadsAIDemoRequests?.data?.length, shallowEqual);

  const [isSearched, setIsSearched] = useState(false);

  const searchNameRef = useRef(null);
  const handleSearch = () => {
    dispatch(
        setDemoRequestFilters({
          searchByName: searchNameRef?.current?.value,
        }),
    );
  };

  const handleClear = () => {
    if (searchNameRef?.current?.value) searchNameRef.current.value = '';
    setIsSearched(false);
    dispatch(setDemoRequestFilters({searchByName: ''}));
  };

  return (
      <TableHead>
        <div className="table__search">
          <div className="table__search_item">
            <b>Demo Requests</b>
            <div className="form" style={{width: 300}}>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <div className="form__form-group-icon" style={{height: 37}}>
                    <MagnifyIcon/>
                  </div>
                  <input
                      ref={searchNameRef}
                      className="search-bar-search-text"
                      name="searchText"
                      type="text"
                      placeholder="Search By Demo Requester Name"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="table__search_item mt-3">
            <Button color="primary" onClick={handleSearch}>
              {' '}
              Search{' '}
            </Button>
          </div>
          <div className="table__search_item mt-3">
            <Button color="secondary" onClick={handleClear} outline>
              Clear
            </Button>
          </div>
        </div>

        {isSearched && (
            <div className="ml-1">
              <span>Found {totalDemoRequestCount} matches</span>
            </div>
        )}
      </TableHead>
  );
}
