import { handleActions } from 'redux-actions';
import { fetchDemoRequestList, setDemoRequestError, setDemoRequestFilters, setDemoRequestList, } from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  error: null,
};

export default handleActions(
    {
      [fetchDemoRequestList](state) {
        return {
          ...state,
          loading: true,
          data: [],
        };
      },
      [setDemoRequestList](state, {payload}) {
        return {
          ...state,
          loading: false,
          data: payload?.data?.records,
          totalRecords: payload?.data?.totalRecords,
          error: null,
        };
      },
      [setDemoRequestError](state, {payload}) {
        return {
          ...state,
          data: [],
          loading: false,
          totalPages: defaultState.totalPages,
          error: payload?.message,
        };
      },
      [setDemoRequestFilters](state, {payload}) {
        return {
          ...state,
          filters: {
            ...state.filters,
            ...payload,
          },
          checkedHelpIds: [],
        };
      },
    },
    defaultState,
);
