import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { assessmentsBaseUrl } from '../index';
import { AssessmentUIProvider } from './AssessmentUIProvider';
import { AssessmentCard } from './AssessmentCard';
import AssessmentEditDialog from './assessment-add-edit-dialog/AssessmentEditDialog';
import AssessmentDuplicateDialog from './assessment-duplicate-dialog/AssessmentDuplicateDialog';
import AssessmentDeleteDialog from './assessment-delete-dialog/AssessmentDeleteDialog';
import AssessmentViewDialog from './assessment-view-dialog/AssessmentViewDialog';
import { setAssessmentFilters } from '../redux/actions';
import { useDispatch } from 'react-redux';

export function AssessmentsPage({ history, match }) {
  const assessmentUIEvents = {
    openViewAssessmentList: () => {
      history.push(assessmentsBaseUrl);
    },
    openViewAssessmentDialog: (id) => {
      history.push(`${assessmentsBaseUrl}/${id}/view`);
    },
    openNewAssessmentDialog: () => {
      history.push(`${assessmentsBaseUrl}/new`);
    },
    openEditAssessmentDialog: (id) => {
      history.push(`${assessmentsBaseUrl}/${id}/edit`);
    },
    openDeleteAssessmentDialog: (id) => {
      history.push(`${assessmentsBaseUrl}/${id}/delete`);
    },
    openAssessmentDuplicateDialog: (id) => {
      history.push(`${assessmentsBaseUrl}/${id}/duplicate`);
    },
    openAssessmentConfigureDialog: (id, tags) => {
      history.push(`${assessmentsBaseUrl}/${id}/configure${tags ? "?tags=true" : ""}`);
    },
  };

  //TODO: remove this once we have add and edit api returning newly created data
  const dispatch = useDispatch();

  return (
    <AssessmentUIProvider assessmentUIEvents={assessmentUIEvents}>
      <Switch>
        <Route path={`${assessmentsBaseUrl}/new`}>
          {({ history, match }) => (
            <AssessmentEditDialog
              show={match != null}
              isEdit={false}
              onHide={() => {
                dispatch(setAssessmentFilters());
                history.push(assessmentsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${assessmentsBaseUrl}/:id/edit`}>
          {({ history, match }) => (
            <AssessmentEditDialog
              show={match != null}
              isEdit={true}
              id={match?.params?.id}
              onHide={() => {
                dispatch(setAssessmentFilters()); //TODO: remove this and do in-place replace in ui to avoid api call
                history.push(assessmentsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${assessmentsBaseUrl}/:id/duplicate`}>
          {({ history, match }) => (
            <AssessmentDuplicateDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(assessmentsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${assessmentsBaseUrl}/:id/delete`}>
          {({ history, match }) => (
            <AssessmentDeleteDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(assessmentsBaseUrl);
              }}
            />
          )}
        </Route>
        <Route path={`${assessmentsBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <AssessmentViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(assessmentsBaseUrl);
              }}
            />
          )}
        </Route>
      </Switch>
      <AssessmentCard />
    </AssessmentUIProvider>
  );
}
