import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchTagList = createAction('FETCH_TAG_LIST');
export const setTagList = createAction('SET_TAG_LIST');
export const setTagError = createAction('SET_TAG_ERROR');
export const setTagFilters = createAction('SET_TAG_FILTERS');

export const setTagOptionsList = createAction('SET_TAG_OPTIONS_LIST');

export const getTagsList = (filter) => async (dispatch) => {
  const defaultError = 'Error occurred getting tags list';
  try {
    dispatch(fetchTagList());
    const {data: res, isSuccess} = await api.tags.getTagList(filter);
    if (isSuccess) dispatch(setTagList(res.data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setTagError(error));
    console.error(defaultError, error.message);
  }
};

