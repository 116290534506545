import React from 'react';
import { Button } from 'reactstrap';
import Select from 'react-select';
import TableHead from '@material-ui/core/TableHead';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setAssignmentFilters } from '../../redux/actions';
import { getCustomersListOptions } from "@/containers/Admin/Customers/redux/actions";
import { getAssessmentListOptions } from "@/containers/Admin/Assessments/redux/actions";

export function AssignmentTableSearchBar() {
  const dispatch = useDispatch();
  const totalAssignmentCount = useSelector((state) => state.assignments?.data?.length, shallowEqual);
  const [isSearched, setIsSearched] = React.useState(false);

  const organizationAllOpts = useSelector((state) => state.customers?.options, shallowEqual);
  const [organizationOptions, setOrganizationOptions] = React.useState({value: null, label: 'All'});
  const [searchOrganization, setSearchOrganization] = React.useState({value: null, label: 'All'});
  React.useEffect(() => {
    dispatch(getCustomersListOptions());
  }, []);
  React.useEffect(() => {
    setOrganizationOptions([
      {value: null, label: 'All'}, //adding default select all option
      ...(organizationAllOpts?.map((org) => ({
        value: org.id,
        label: org.title,
      })) ?? []),
    ]);
  }, [organizationAllOpts]);

  const assessmentAllOpts = useSelector((state) => state.assessments?.options, shallowEqual);
  const [assessmentOptions, setAssessmentOptions] = React.useState({value: null, label: 'All'});
  const [searchAssessment, setSearchAssessment] = React.useState({value: null, label: 'All'});
  React.useEffect(() => {
    dispatch(getAssessmentListOptions());
  }, []);
  React.useEffect(() => {
    setAssessmentOptions([
      {value: null, label: 'All'}, //adding default select all option
      ...(assessmentAllOpts?.map((assessment) => ({
        value: assessment.id,
        label: assessment.title,
      })) ?? []),
    ]);
  }, [assessmentAllOpts]);

  const handleSearch = () => {
    dispatch(
        setAssignmentFilters({
          organizationId: searchOrganization?.value,
          assessmentId: searchAssessment?.value,
          pageNumber: 1,
        }),
    );
  };

  React.useEffect(() => {
    handleSearch();
  }, [searchOrganization, searchAssessment]);

  const handleClear = () => {
    setIsSearched(false);
    dispatch(setAssignmentFilters({organizationId: '', assessmentId: ''}));
    setSearchOrganization({value: null, label: 'All'});
    setSearchAssessment({value: null, label: 'All'});
  };

  return (
      <TableHead>
        <div className="table__search">
          <div className="table__search_item">
            <b>Organization</b>
            <div style={{width: 300}}>
              <Select
                  name="status"
                  value={searchOrganization}
                  onChange={setSearchOrganization}
                  autosize={true}
                  options={organizationOptions}
                  clearable={false}
                  className="react-select"
                  placeholder={'Select Status'}
                  classNamePrefix="react-select"
              />
            </div>
          </div>
          <div className="table__search_item">
            <b>Assessment</b>
            <div style={{width: 300}}>
              <Select
                  name="status"
                  value={searchAssessment}
                  onChange={setSearchAssessment}
                  autosize={true}
                  options={assessmentOptions}
                  clearable={false}
                  className="react-select"
                  placeholder={'Select Status'}
                  classNamePrefix="react-select"
              />
            </div>
          </div>
          {/*          <div className="table__search_item mt-3">
            <Button color="primary" onClick={handleSearch}>
              {' '}
              Search{' '}
            </Button>
          </div>*/}
          <div className="table__search_item mt-3">
            <Button color="secondary" onClick={handleClear} outline>
              Clear
            </Button>
          </div>
        </div>

        {isSearched && (
            <div className="ml-1">
              <span>Found {totalAssignmentCount} matches</span>
            </div>
        )}
      </TableHead>
  );
}
