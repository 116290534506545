import { handleActions } from 'redux-actions';
import {
  deleteAssessmentFromList,
  fetchAssessmentList,
  setAssessmentCategoryOptionsList,
  setAssessmentDataSkillOptionsList,
  setAssessmentError,
  setAssessmentFilters,
  setAssessmentList,
  setAssessmentOptionsList,
  setAssessmentTypeOptionsList,
} from './actions';

const defaultState = {
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    sortBy: '',
    search: '',
    isSortOrderDescending: false,
    organizationId: null,
    status: null,
  },
  totalRecords: 0,
  data: [],
  options: [],
  categoryOptions: [],
  typeOptions: [],
  dataSkillOptions: [],
  error: null,
};

export default handleActions(
  {
    [fetchAssessmentList](state) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    [setAssessmentList](state, { payload }) {
      return {
        ...state,
        loading: false,
        data: payload.data.records,
        totalRecords: payload.data.totalRecords,
        error: null,
      };
    },
    [setAssessmentCategoryOptionsList](state, { payload }) {
      return {
        ...state,
        loading: false,
        categoryOptions: payload.data,
      };
    },
    [setAssessmentTypeOptionsList](state, { payload }) {
      return {
        ...state,
        loading: false,
        typeOptions: payload.data,
      };
    },
    [setAssessmentDataSkillOptionsList](state, {payload}) {
      return {
        ...state,
        loading: false,
        dataSkillOptions: payload.data,
      };
    },
    [setAssessmentOptionsList](state, {payload}) {
      return {
        ...state,
        loading: false,
        options: payload.data,
      };
    },
    [setAssessmentError](state, {payload}) {
      return {
        ...state,
        data: [],
        loading: false,
        totalPages: defaultState.totalPages,
        error: payload?.message,
      };
    },
    [setAssessmentFilters](state, {payload}) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    },
    [deleteAssessmentFromList](state, { payload: assessmentId }) {
      return {
        ...state,
        data: state.data.filter((assessment) => assessment.id !== assessmentId),
        totalRecords: state.totalRecords - 1,
      };
    },
  },
  defaultState,
);
