import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { StepDefaultProps, StepPropTypes } from './Props';
import FormField from '@/shared/components/form/hook/FormField';
import { SelectField } from '@/shared/components/form/Select';
import { convertUrlToFile, emailPattern } from '@/shared/helpers';
import api from '@/utils/apiClient';
import { extractFieldData, getStepFields } from '../index';
import renderDropZoneField from '@/shared/components/form/DropZone';
import { getOrganizationTypeList } from '@/containers/Cads-AI/redux/organization/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const StepOne = ({ onSubmit, page, data, isEdit }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    formState: {errors},
  } = useForm(extractFieldData(page, data));
  const orgTypes = useSelector((state) => state.cadsAIOrgs.types, shallowEqual);
  const [organizationTypeOptions, setOrganizationTypeOptions] = React.useState([]);

  const setImage = async () => {
    if (data?.logo) {
      const imgFile = await convertUrlToFile(data?.logo);
      setValue('logo', imgFile ? [imgFile] : '');
    }
  };

  React.useEffect(() => {
    getStepFields(page).forEach((key) => setValue(key, data[key]));
    setImage();
  }, [data]);

  React.useEffect(() => {
    getCountryList();
    dispatch(getOrganizationTypeList());
  }, []);

  React.useEffect(() => {
    setOrganizationTypeOptions(
      orgTypes?.map((type) => ({ value: type.id, label: type.title, ...type })),
    );
  }, [orgTypes]);

  const [countries, setCountries] = React.useState([]);
  const getCountryList = async () => {
    const { data: countries, isSuccess } = await api.cadsAICountry.getCountryList();
    if (isSuccess) {
      setCountries(countries.data.map((country) => ({ value: country.id, label: country.title })));
    }
  };

  return (
    <form className="form wizard__form step-one" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__form-group">
        <span className="form__form-group-label">Name</span>
        <div className="form__form-group-field">
          <FormField
            name="title"
            as="input"
            placeholder="Enter Name"
            control={control}
            defaultValue=""
            errors={errors}
            rules={{ required: 'This is required field' }}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Organization Type</span>
        <div className="form__form-group-field">
          <FormField
            control={control}
            name="organizationType"
            component={SelectField}
            options={organizationTypeOptions}
            disabled={isEdit}
            placeholder="Select organization type"
            rules={{ required: 'This is required field' }}
            errors={errors}
            isAboveError
          />
        </div>
      </div>
      {/* <div className="form__form-group">
          <span className="form__form-group-label">Registration Number</span>
          <div className="form__form-group-field">
            <FormField
                name="regNo"
                as="input"
                placeholder="Enter registration number"
                control={control}
                defaultValue=""
                errors={errors}
                rules={{required: 'This is required field'}}
                isAboveError
            />
          </div>
        </div> */}
      <div className="form__form-group">
        <span className="form__form-group-label">City</span>
        <div className="form__form-group-field">
          <FormField
            name="city"
            placeholder="Enter City"
            control={control}
            defaultValue=""
            errors={errors}
            rules={{ required: 'This is required field' }}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Country</span>
        <div className="form__form-group-field">
          <FormField
            control={control}
            name="country"
            component={SelectField}
            options={countries}
            placeholder="Select country"
            rules={{ required: 'This is required field' }}
            errors={errors}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Revenue Size</span>
        <div className="form__form-group-field">
          <FormField
            name="revenueSize"
            as="input"
            type="text"
            placeholder="Enter Revenue Size"
            control={control}
            defaultValue=""
            errors={errors}
            rules={{
              required: 'This is required field',
              min: { value: 0, message: 'Can not have negative value' },
            }}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Main Office City</span>
        <div className="form__form-group-field">
          <FormField
            name="mainOfficeCity"
            as="input"
            type="text"
            placeholder="Main Office City"
            control={control}
            defaultValue=""
            errors={errors}
            rules={{ required: 'This is required field' }}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Department Name</span>
        <div className="form__form-group-field">
          <FormField
            name="departmentName"
            as="input"
            type="text"
            placeholder="Enter Department Name"
            control={control}
            defaultValue=""
            errors={errors}
            rules={{ required: 'This is required field' }}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">E-mail</span>
        <div className={isEdit ? 'form__disabled-input' :'form__form-group-field'}>
          <FormField
            name="email"
            as="input"
            type="email"
            placeholder="Enter email"
            control={control}
            errors={errors}
            disabled={isEdit}
            isAboveError
            defaultValue=""
            rules={{
              required: 'This is required field',
              pattern: {
                value: emailPattern,
                message: 'Entered value does not match email format',
              },
            }}
          />
        </div>
      </div>
      {/* <div className="form__form-group">
          <span className="form__form-group-label d-block">HostName</span>
          <div className="form__form-group-field d-inline-block w-50">
            <FormField
                name="subdomain"
                as="input"
                type="text"
                placeholder="Subdomain"
                control={control}
                defaultValue=""
                errors={errors}
                rules={{
                  required: 'This is required field',
                  pattern: {
                    value: /^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/,
                    message: 'Please enter valid subdomain'
                  }
                }}
                isAboveError
            />
          </div>
          <p className="d-inline-block">.talentspy.staging.cads.ai</p>

        </div> */}
      <div className="form__form-group">
        <span className="form__form-group-label">Logo</span>
        <div className="form__form-group-field border">
          <FormField
            name="logo"
            as="input"
            placeholder="Enter Logo"
            control={control}
            component={renderDropZoneField}
            defaultValue=""
          />
        </div>
      </div>
      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button color="primary" type="button" disabled className="previous">
          Back
        </Button>
        <Button color="primary" type="submit" className="next">
          Next
        </Button>
      </ButtonToolbar>
    </form>
  );
};

StepOne.propTypes = StepPropTypes;
StepOne.defaultProps = StepDefaultProps;

export default StepOne;
