import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { Badge, Button } from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import PencilIcon from 'mdi-react/PencilIcon';
// import BinIcon from 'mdi-react/BinIcon';
import { useQuestionUIContext } from '../QuestionUIProvider';
import QuestionTableColumns, { QuestionColumns } from './QuestionTableColumns';
import { QuestionTableSearchBar } from './QuestionTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getQuestionsList, setQuestionFilters } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps, getColorForWeight } from '@/shared/helpers';
import BinIcon from 'mdi-react/BinIcon';
import SanitizeHTML from '@/shared/components/SanitizeHTML';
import { useLocation } from "react-router-dom";

const QuestionTable = () => {
  const questionUIContext = useQuestionUIContext();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    data: questions,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.questions, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  React.useEffect(() => {
    const {tagId = null} = location?.state ?? {};
    if (tagId) {
      filters.tags = `${tagId}`;
      // clear the state once filtered by tagId once
      location.state = {};
    }
    dispatch(getQuestionsList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
      setQuestionFilters({ sortBy: property, isSortOrderDescending: !isSortOrderDescending }),
    );
  };

  const handleChangePage = (event, currentPage) => {
    dispatch(setQuestionFilters({ pageSize: rowsPerPage, pageNumber: currentPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setQuestionFilters({ pageSize: +event.target.value, pageNumber: 1 }));
  };

  const emptyRows =
    page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - questions.length : 0;

  const renderActionButtons = ({ id: questionId }) => (
    <div className="questions-actions">
      <Button
        className="icon"
        color="success"
        outline
        onClick={() => questionUIContext.openViewQuestionDialog(questionId)}
      >
        <p>
          <EyeIcon /> View
        </p>
      </Button>
      <Button
        className="icon"
        color="primary"
        outline
        onClick={() => questionUIContext.openEditQuestionDialog(questionId)}
      >
        <p>
          <PencilIcon /> Edit
        </p>
      </Button>
      <Button
        className="icon"
        color="danger"
        outline
        onClick={() => questionUIContext.openDeleteQuestionDialog(questionId)}
      >
        <p>
          <BinIcon /> Delete
        </p>
      </Button>
    </div>
  );

  return (
    <Fragment>
      <div className="material-table__wrap">
        <QuestionTableSearchBar />
        <Table className="material-table">
          <QuestionTableColumns
            order={isSortOrderDescending ? 'desc' : 'asc'}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsPerPage}
          />
          <TableBody>
            {(loading || !!error) && (
              <TableRow>
                <TableCell colSpan={QuestionColumns.length}>
                  {loading && (
                    <div className={'text-center overflow-hidden'}>
                      <div className="panel__refresh position-relative" style={{ height: 75 }}>
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                  <div className={'text-center'}>
                    <p className={!!error ? 'm-5' : ''}>{error || 'Loading questions...'}</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!!!questions.length && !loading && !error && (
              <TableRow style={{ height: 120 }}>
                <TableCell colSpan={QuestionColumns.length} align="left">
                  {filters.status === null && filters.search === ''
                    ? 'No Questions'
                    : 'No Questions with applied filters'}
                </TableCell>
              </TableRow>
            )}
            {questions.map((question) => {
              return (
                <TableRow className="material-table question-row" tabIndex={-1} key={question?.id}>
                  <TableCell
                      align="left"
                      className="material-table__cell question-text"
                      component="th"
                      scope="row"
                  >
                    <div className="question-header">
                      <p>{question?.skill.name}</p>
                      <Badge color="success">{question?.questionLevel?.name}</Badge>
                    </div>
                    {question?.tags !== "" &&
                        <div className="question-header">
                          <p style={{marginRight: 10}}>Tags: </p>
                          {question?.tags.split(',').map(tag => (
                              <Button style={{marginBottom: 0, padding: 3, pointerEvents: 'none'}} outline={true}
                                      color={getColorForWeight({cssClass: true, random: true})}>
                                {tag}
                              </Button>
                          ))}
                        </div>
                    }
                    <p className="question-main">{question?.questionMain}</p>
                    {!!question?.questionExtra && (
                        <div className="question-extra-html">
                          <SanitizeHTML html={question.questionExtra ?? '<p/>'}/>
                        </div>
                    )}
                    {!!question?.imageUrl && <img alt="Question Image" src={question?.imageUrl}/>}
                  </TableCell>
                  <TableCell align="left" className="material-table__cell question-text">
                    <ul>
                      {question?.correctAnswers?.map((ans) => (
                        <li key={ans}>{ans}</li>
                      ))}
                    </ul>
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {question?.submittedAnswers}
                  </TableCell>
                  <TableCell align="center" className="material-table__cell">
                    {question?.correctAnswerRate}%
                  </TableCell>
                  <TableCell align="left" className="material-table__cell">
                    {renderActionButtons(question)}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={QuestionColumns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        component={defaultPaginationProps.component}
        backIconButtonProps={defaultPaginationProps.backIconButton}
        nextIconButtonProps={defaultPaginationProps.nextIconButton}
        rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
        className={defaultPaginationProps.className}
        dir={defaultPaginationProps.dir}
      />
    </Fragment>
  );
};

export default QuestionTable;
