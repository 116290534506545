import React, { useState } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import api from '@/utils/apiClient';
import OrganizationWizardForm from './OrganizationWizardForm';
import { convertImageFileToBase64 } from '@/shared/helpers';

const OrganizationEditForm = ({ modal, isEdit, id }) => {
  const initialValues = {
    userDetailsId: null,
    title: '',
    city: '',
    country: null,
    email: '',
    logo: '',
    firstName: '',
    lastName: '',
    // role: { value: 'admin', label: 'Admin' }, //TODO: remove default value when we have multiple values for role
    shortDescription: '',
    departmentName: '',
    mainOfficeCity: '',
    revenueSize: '',
    isDDO: false,
    isBA: false,
    isDLE: false
  };

  const [data, setData] = useState(initialValues);

  const [state, setState] = React.useState({
    loading: false,
    fetchingOrganization: false,
    newOrganizationAdded: false,
    errorMessage: '',
  });

  React.useEffect(() => {
    if (isEdit && id) {
      setDefaultData(+id);
    }
  }, [isEdit, id]);

  const setDefaultData = async (organizationId) => {
    try {
      const {data: res, isSuccess} = await api.cadsAIOrganization.getOrganization(organizationId);
      let {
        userDetailsId,
        title,
        address,
        noOfEmp,
        email,
        subdomain,
        logo,
        firstName,
        lastName,
        notes,
        phoneNumberOffice,
        phoneNumberMobile,
        countryId,
        countryName,
        organizationTypeId,
        organizationTypeName,
        registrationNumber,
        city,
        revenueSize,
        mainOfficeCity,
        departmentName,
        shortDescription,
        isDDO,
        isBA,
        isDLE
      } = res.data;
      if (isSuccess) {
        setData({
          revenueSize,
          departmentName,
          shortDescription,
          mainOfficeCity,
          userDetailsId,
          title,
          address,
          noOfEmp,
          email,
          subdomain,
          logo,
          firstName,
          lastName,
          notes,
          city,
          regNo: registrationNumber,
          country: {value: countryId, label: countryName},
          role: {value: 'admin', label: 'Admin'}, //TODO: remove default value when we have multiple values for role
          companyOfficePhoneNo: phoneNumberOffice,
          companyOfficeMobileNo: phoneNumberMobile,
          organizationType: {
            value: organizationTypeId,
            label: organizationTypeName,
          },
          isDDO,
          isBA,
          isDLE
        });
      } else {
        throw new Error(res?.messages?.[0]);
      }
    } catch ({ message }) {
      console.error('Organization edit error : ', message);
      setState({
        ...state,
        errorMessage: 'Error while getting existing organization details',
      });
    }
  };

  const constructApiRequestData = async ({
    title,
                                           city,
                                           email,
                                           subdomain,
                                           firstName,
                                           lastName,
                                           shortDescription,
                                           departmentName,
                                           mainOfficeCity,
                                           revenueSize,
                                           organizationType,
                                           isDDO,
                                           isBA,
                                           isDLE,
                                           ...data
                                         }) => {
    const base64Img = await convertImageFileToBase64(data.logo?.[0]);
    return {
      title,
      city,
      email,
      firstName,
      lastName,
      shortDescription,
      departmentName,
      mainOfficeCity,
      revenueSize,
      organizationTypeId: organizationType.value,
      countryId: data.country?.value,
      logo: base64Img?.split(',')?.[1],
      industryId: 1,
      registerIsB2b: true,
      isB2b: true,
      isDDO,
      isBA,
      isDLE
    };
  };

  const handleAddEditOrganization = async (organizationData, clearForm) => {
    setState({ ...state, loading: true });
    const newOrganizationData = await constructApiRequestData(organizationData);

    const defaultError = `Error occurred while ${isEdit ? 'Updating' : 'Adding new'} organization`;
    try {
      const { data, isSuccess } = isEdit
          ? await api.cadsAIOrganization.updateOrganization({...newOrganizationData, id: +id})
          : await api.cadsAIOrganization.addOrganization(newOrganizationData);
      if (isSuccess) {
        setState({ ...state, newOrganizationAdded: true, loading: false });
        if (!isEdit) clearForm();
      } else {
        throw new Error(data?.messages?.[0] ?? defaultError);
      }
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        loading: false,
        newOrganizationAdded: false,
        errorMessage: error.message,
      });
    }
  };

  return (
    <div>
      {/*Add/Edit Organization Form Status*/}
      <div className="ml-4">
        {state.loading && (
          <div>
            <div className="panel__refresh position-relative" style={{ height: 75, width: 20 }}>
              <LoadingIcon/>
              <div style={{width: 'max-content', padding: '23px 40px'}}>
                {isEdit ? 'Updating' : 'Adding new'} organization...
              </div>
            </div>
          </div>
        )}
        {!state.loading && (state.newOrganizationAdded || !!state.errorMessage) && (
          <table className="mt-3">
            <tr>
              <td>
                {state.newOrganizationAdded ? (
                  <span className="lnr lnr-thumbs-up modal__title-icon success" />
                ) : (
                  <span className="lnr lnr-cross-circle modal__title-icon error" />
                )}
              </td>
              <td className="pl-2">
                {state.newOrganizationAdded
                  ? `${isEdit ? 'Organization Updated' : 'New Organization Added'} successfully`
                  : state.errorMessage}
              </td>
            </tr>
          </table>
        )}
      </div>

      {/*Add/Edit Organization Form*/}
      <OrganizationWizardForm
        onSubmit={handleAddEditOrganization}
        initialValues={initialValues}
        data={data}
        setData={setData}
        isEdit={isEdit}
      />
    </div>
  );
};

export default OrganizationEditForm;
