import { createAction } from 'redux-actions';
import api from '@/utils/apiClient';

export const fetchDemoRequestList = createAction('CADS_AI_DEMO_REQUESTS_LIST');
export const setDemoRequestList = createAction('CADS_AI_SET_DEMO_REQUESTS_LIST');
export const setDemoRequestError = createAction('CADS_AI_SET_DEMO_REQUESTS_ERROR');
export const setDemoRequestFilters = createAction('CADS_AI_SET_DEMO_REQUESTS_FILTERS');

export const getDemoRequestList = (filter) => async (dispatch) => {
  const defaultError = `Error occurred getting demo request list`;
  dispatch(fetchDemoRequestList());
  try {
    const {data, isSuccess} = await api.cadsAIDemoRequest.getDemoRequestList(filter);
    if (isSuccess) dispatch(setDemoRequestList(data));
    else throw new Error(data?.messages?.[0] ?? defaultError);
  } catch (error) {
    dispatch(setDemoRequestError(error.message));
    console.error(defaultError, error.message);
  }
};
