import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { assignmentsBaseUrl } from '../index';
import { AssignmentUIProvider } from './AssignmentUIProvider';
import { AssignmentCard } from './AssignmentCard';
import AssignmentEditDialog from './assignment-add-edit-dialog/AssignmentEditDialog';
// import AssignmentDeleteDialog from "./assignment-delete-dialog/AssignmentDeleteDialog";
// import AssignmentViewDialog from './assignment-view-dialog/AssignmentViewDialog';
import { setAssignmentFilters } from '../redux/actions';
import { useDispatch } from 'react-redux';

export function AssignmentsPage({history, match}) {
  const assignmentUIEvents = {
    openViewAssignmentDialog: (id) => {
      history.push(`${assignmentsBaseUrl}/${id}/view`);
    },
    openNewAssignmentDialog: () => {
      history.push(`${assignmentsBaseUrl}/new`);
    },
    openEditAssignmentDialog: (id) => {
      history.push(`${assignmentsBaseUrl}/${id}/edit`);
    },
    openDeleteAssignmentDialog: (id) => {
      history.push(`${assignmentsBaseUrl}/${id}/delete`);
    },
  };

  //TODO: remove this once we have add and edit api returning newly created data
  const dispatch = useDispatch();

  return (
      <AssignmentUIProvider assignmentUIEvents={assignmentUIEvents}>
        <Switch>
          <Route path={`${assignmentsBaseUrl}/new`}>
            {({history, match}) => (
                <AssignmentEditDialog
                    show={match != null}
                    isEdit={false}
                    onHide={() => {
                      dispatch(setAssignmentFilters());
                      history.push(assignmentsBaseUrl);
                    }}
                />
            )}
          </Route>
          <Route path={`${assignmentsBaseUrl}/:id/edit`}>
            {({history, match}) => (
                <AssignmentEditDialog
                    show={match != null}
                    isEdit={true}
                    id={match?.params?.id}
                    onHide={() => {
                      dispatch(setAssignmentFilters());
                      history.push(assignmentsBaseUrl);
                    }}
                />
            )}
          </Route>
          {/*       <Route path={`${assignmentsBaseUrl}/:id/view`}>
          {({ history, match }) => (
            <AssignmentViewDialog
              show={match != null}
              id={match?.params?.id}
              onHide={() => {
                history.push(assignmentsBaseUrl);
              }}
            />
          )}
        </Route>*/}
          {/*                <Route path={`${assignmentsBaseUrl}/:id/delete`}>
                    {({history, match}) => (
                        <AssignmentDeleteDialog
                            show={match != null}
                            id={match?.params?.id}
                            onHide={() => {
                                history.push(assignmentsBaseUrl);
                            }}
                        />
                    )}
                </Route>*/}
        </Switch>
        <AssignmentCard/>
      </AssignmentUIProvider>
  );
}
