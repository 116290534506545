import React from 'react';
import { getColorForWeight } from "@/shared/helpers";

export function AssessmentViewBasicDetails({ assessment }) {
  return (
    <table className="project-summary__info">
      <tbody>
        {/*<tr>
                <th>Name</th>
                <td>{assessment?.title}</td>
            </tr>*/}
        <tr>
          <th>Description</th>
          <td>{assessment?.description}</td>
        </tr>
        <tr>
          <th>Assessment Category</th>
          <td>{assessment?.assessmentCategory?.title}</td>
        </tr>
        <tr>
          <th>Assessment Type</th>
          <td>{assessment?.assessmentType?.title}</td>
        </tr>
        <tr>
          <th>Customer</th>
          <td>{assessment?.customer?.title}</td>
        </tr>
        <tr>
          <th>Domain</th>
          <td>{assessment?.domain?.title}</td>
        </tr>
        <tr>
          <th>Subcategory</th>
          <td>{assessment?.subcategory?.title}</td>
        </tr>
        <tr>
          <th>Tags</th>
          <td className="pt-4">
            {assessment?.tags?.map((tag) => (
                <button
                    className={`btn btn-${getColorForWeight({random: true, cssClass: true})} btn-sm`}
                >
                  {tag?.name}
                </button>
            ))}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
