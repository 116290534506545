import React, { useMemo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useAssignmentUIContext } from './AssignmentUIProvider';
import AssignmentTableWrapper from './table/AssignmentTableWrapper';

export function AssignmentCard() {
  const assignmentsUIContext = useAssignmentUIContext();
  const assignmentsUIProps = useMemo(() => {
    return {
      openNewAssignmentDialog: assignmentsUIContext.openNewAssignmentDialog,
    };
  }, [assignmentsUIContext]);

  return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Assignment List</h3>
            <h3 className="page-subhead subhead">From here you can manage all the assignments.</h3>
          </Col>
        </Row>
        <Row>
          <AssignmentTableWrapper/>
        </Row>
      </Container>
  );
}
