import React, { Fragment, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { useDemoRequestsUIContext } from '../DemoRequestsUIProvider';
import DemoRequestsTableColumns, { DemoRequestsColumns } from './DemoRequestsTableColumns';
import { DemoRequestsTableSearchBar } from './DemoRequestsTableSearchBar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getDemoRequestList, setDemoRequestFilters } from '../../redux/actions';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { defaultPaginationProps } from '@/shared/helpers';
import moment from 'moment';

const DemoRequestsTable = () => {
  const demoRequestUIContext = useDemoRequestsUIContext();
  const dispatch = useDispatch();
  const {
    data: demoRequests,
    error,
    filters,
    loading,
    totalRecords,
  } = useSelector((state) => state.cadsAIDemoRequests, shallowEqual);
  const {
    pageSize: rowsPerPage,
    pageNumber: page,
    sortBy: orderBy,
    isSortOrderDescending,
  } = filters;

  const [columns, setColumns] = useState(0);

  useEffect(() => {
    setColumns(DemoRequestsColumns.length);
  }, [DemoRequestsColumns.length]);

  React.useEffect(() => {
    dispatch(getDemoRequestList(filters));
  }, [filters]);

  const handleRequestSort = (event, property) => {
    dispatch(
        setDemoRequestFilters({
          sortBy: property,
          isSortOrderDescending: !isSortOrderDescending,
        }),
    );
  };

  const renderActionButtons = (id) => (
      <div className="actions">
        {/*  <Button
        className="icon mb-0"
        color="success"
        outline
        onClick={() => demoRequestUIContext.openViewDemoDialog(id)}
      >
        <p>
          <EyeIcon /> Details
        </p>
      </Button>*/}
      </div>
  );

  const handleChangePage = (event, currentPage) => {
    dispatch(setDemoRequestFilters({pageSize: rowsPerPage, pageNumber: currentPage + 1}));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setDemoRequestFilters({pageSize: +event.target.value, pageNumber: 1}));
  };

  const emptyRows = page === Math.ceil(totalRecords / rowsPerPage) ? rowsPerPage - demoRequests.length : 0;

  return (
      <Fragment>
        <div className="material-table__wrap">
          <DemoRequestsTableSearchBar/>
          <Table className="material-table">
            <DemoRequestsTableColumns
                order={isSortOrderDescending ? 'desc' : 'asc'}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rowsPerPage}
            />
            <TableBody>
              {(loading || !!error) && (
                  <TableRow>
                    <TableCell colSpan={columns}>
                      {loading && (
                          <div className={'text-center overflow-hidden'}>
                            <div className="panel__refresh position-relative" style={{height: 75}}>
                              <LoadingIcon/>
                            </div>
                          </div>
                      )}
                      <div className={'text-center'}>
                        <p className={!!error ? 'm-5' : ''}>{error || 'Loading Demo Requests...'}</p>
                      </div>
                    </TableCell>
                  </TableRow>
              )}
              {!!!demoRequests?.length && !loading && !error && (
                  <TableRow style={{height: 120}}>
                    <TableCell colSpan={columns} align="center">
                      {filters.status === null && filters.search === ''
                          ? 'No Demo Requests'
                          : 'No Demo Requests with applied filters'}
                    </TableCell>
                  </TableRow>
              )}
              {demoRequests?.map((demo) => {
                return (
                    <TableRow className="material-table" tabIndex={-1} key={demo?.id}>
                      <TableCell align="center" className="material-table__cell">
                        {demo?.fullName}
                      </TableCell>
                      <TableCell align="center" className="material-table__cell">
                        {demo?.email}
                      </TableCell>
                      <TableCell align="center" className="material-table__cell">
                        {demo.contactNumber}
                      </TableCell>
                      <TableCell align="center" className="material-table__cell">
                        {demo.requestDate ? moment(demo.requestDate).format('DD/MM/yyyy') : '-'}
                      </TableCell>
                      {/*<TableCell
                    align="center"
                    className="material-table__cell"
                    style={{ minWidth: 250 }}
                  >
                    {renderActionButtons(demo?.id)}
                  </TableCell>*/}
                    </TableRow>
                );
              })}
              {emptyRows > 0 && (
                  <TableRow style={{height: 49 * emptyRows}}>
                    <TableCell colSpan={columns}/>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            component={defaultPaginationProps.component}
            backIconButtonProps={defaultPaginationProps.backIconButton}
            nextIconButtonProps={defaultPaginationProps.nextIconButton}
            rowsPerPageOptions={defaultPaginationProps.rowsPerPage}
            className={defaultPaginationProps.className}
            dir={defaultPaginationProps.dir}
        />
      </Fragment>
  );
};

export default DemoRequestsTable;
