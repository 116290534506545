import React, { createContext, useContext } from 'react';

const DemoRequestsUIContext = createContext();

export function useDemoRequestsUIContext() {
  return useContext(DemoRequestsUIContext);
}

export function DemoRequestsUIProvider({demoRequestUIEvents, children}) {
  const value = {
    // openViewDemoDialog: demoRequestUIEvents.openViewDemoDialog,
  };

  return <DemoRequestsUIContext.Provider value={value}>{children}</DemoRequestsUIContext.Provider>;
}
