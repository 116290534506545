import { httpMethod } from '@/shared/helpers/httpMethod.enum';
import { sendRequest } from '../base/ApiService';

const CADS_AI_CP_API =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_CONTROL_PANEL_ENDPOINT;
const CADS_AI_LP_API =
  process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_CADS_AI_LEARNING_PATH_ENDPOINT;

const organizationsEndpoints = {
  ADD_ORGANIZATION: `${CADS_AI_CP_API}/registerOrganization`,
  UPDATE_ORGANIZATION: `${CADS_AI_CP_API}/updateOrganization`,
  GET_ORGANIZATION_LIST: `${CADS_AI_CP_API}/viewAllRegisteredOrgs`,
  GET_ORGANIZATION_LIST_CSV: `${CADS_AI_CP_API}/exportAllRegisteredOrgs`,
  GET_ORGANIZATION_BY_ID: `${CADS_AI_CP_API}/getOrganizationsById`,
  DEACTIVATE_ORGANIZATIONS: `${CADS_AI_CP_API}/deactiveOrganizations`,
  GET_ORGANIZATION_OPTIONS: `${CADS_AI_LP_API}/getOrganizationOptions`,
  GET_ORGANIZATION_TYPE_OPTIONS: `${CADS_AI_LP_API}/getOrganizationTypeOptions`,
};

const addOrganization = (organizationData) => {
  //  return addMockOrganization(organizationData)
  return sendRequest({
    fullUrl: organizationsEndpoints.ADD_ORGANIZATION,
    method: httpMethod.POST,
    data: organizationData,
  });
};

const getOrganizationList = (filter) => {
  return sendRequest({
    fullUrl: organizationsEndpoints.GET_ORGANIZATION_LIST,
    method: httpMethod.GET,
    params: filter,
  });
};

const getOrganizationListCSV = (filter) => {
  return sendRequest({
    fullUrl: organizationsEndpoints.GET_ORGANIZATION_LIST_CSV,
    method: httpMethod.GET,
    params: filter,
  });
};

const getOrganizationTypeList = () => {
  return sendRequest({
    fullUrl: organizationsEndpoints.GET_ORGANIZATION_TYPE_OPTIONS,
    method: httpMethod.GET,
  });
};

const deactivateOrganizations = (orgIds) => {
  // return deactivateMockOrganizations(orgIds);
  return sendRequest({
    fullUrl: organizationsEndpoints.DEACTIVATE_ORGANIZATIONS,
    method: httpMethod.DELETE,
    data: orgIds,
  });
};

const updateOrganizationsType = (data) => {
  return updateMockOrganizationType(data);
  return sendRequest({
    url: '',
    method: httpMethod.PUT,
    data: data,
  });
};

const updateOrganization = (organizationData) => {
  // return updateMockOrganization(organizationData);
  return sendRequest({
    fullUrl: organizationsEndpoints.UPDATE_ORGANIZATION,
    method: httpMethod.PUT,
    data: organizationData,
  });
};

const getOrganization = (id) => {
  // return getMockOrganization();
  return sendRequest({
    fullUrl: organizationsEndpoints.GET_ORGANIZATION_BY_ID,
    method: httpMethod.GET,
    params: {id},
  });
};

const getOrganizationOptionsList = (organizationId) => {
  return sendRequest({
    fullUrl: organizationsEndpoints.GET_ORGANIZATION_OPTIONS,
    method: httpMethod.GET,
    params: { orgTypeId: organizationId },
  });
};

/*MOCKS*/

const getMockOrganization = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              address: null,
              companyName: 'mtdc',
              country: { id: 1, name: 'Iran' },
              city: 'abc',
              organizationType: { id: 2, title: 'Platform Organization', code: 'platform_org' },
              countryId: 1,
              email: 'mock1@gmail.com',
              firstName: 'mock 1',
              lastName: 'test',
              noOfEmp: null,
              notes: null,
              organizationId: 9474,
              phoneNumberMobile: '',
              phoneNumberOffice: null,
              registrationNumber: null,
              revenueSize: 20,
              subdomain: 'b2cplatform',
              userDetailsId: 299,
              mainOfficeCity: 'NYC',
              departmentName: 'Default',
              shortDescription: 'description test',
              isDDO: false,
              isBA: true,
              isDLE: true
            },
          },
        }),
      500,
    );
  });
};

const addMockOrganization = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          body: responseData,
        }),
      500,
    );
  });
};

const updateMockOrganization = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          body: responseData,
        }),
      500,
    );
  });
};

const getMockOrganizationList = (fromTab = false) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 3,
              records: fromTab
                ? [
                    {
                      id: 1,
                      orgEmail: 'mock-adminuserb2c@yopmail.com',
                      orgName: 'B2c Platform 1',
                      tenantId: 27,
                    },
                    {
                      id: 3,
                      orgEmail: 'mock-adminuserb2c@yopmail.com',
                      orgName: 'B2c Platform 2',
                      tenantId: 25,
                    },
                    {
                      id: 5,
                      orgEmail: 'mock-adminuserb2c@yopmail.com',
                      orgName: 'B2c Platform 3',
                      tenantId: 5,
                    },
                  ]
                : [
                    {
                      assessmentAssigned: 0,
                      assessmentCompleted: 0,
                      assessmentPending: 0,
                      organizationId: 9474,
                      organizationName: 'Mock 1',
                      status: true,
                      usersCount: 1,
                    },
                    {
                      assessmentAssigned: 0,
                      assessmentCompleted: 0,
                      assessmentPending: 0,
                      organizationId: 47,
                      organizationName: 'Mock 2',
                      status: true,
                      usersCount: 1,
                    },
                    {
                      assessmentAssigned: 0,
                      assessmentCompleted: 0,
                      assessmentPending: 0,
                      organizationId: 46,
                      organizationName: 'Mock 3',
                      status: true,
                      usersCount: 0,
                    },
                  ],
            },
          },
        }),
      500,
    );
  });
};

const getMockOrganizationType = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        data: {
          status: 200,
          messages: [],
          records: [
            { name: 'Type 1', id: 1 },
            { name: 'Type 2', id: 2 },
            { name: 'Type 3', id: 3 },
          ],
        },
      });
    }, 500);
  });
};

const updateMockOrganizationType = (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
      });
    }, 500);
  });
};
const deactivateMockOrganizations = (orgIds) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
      });
    }, 500);
  });
};

const getMockOrganizationTypeList = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve({
          isSuccess: true,
          data: {
            status: 200,
            messages: [],
            data: {
              totalRecords: 3,
              records: [
                {
                  id: 1,
                  title: 'Mock 10',
                  code: 'any',
                },
                {
                  id: 2,
                  title: 'Mock 20',
                  code: 'any',
                },
                {
                  id: 3,
                  title: 'Mock 30',
                  code: 'any',
                },
              ],
            },
          },
        }),
      500,
    );
  });
};

export default {
  addOrganization,
  updateOrganization,
  getOrganization,
  getOrganizationList,
  getOrganizationListCSV,
  getOrganizationTypeList,
  getOrganizationOptionsList,

  updateOrganizationsType,
  deactivateOrganizations,
};
