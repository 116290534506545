import React from 'react';
import { TagUIProvider } from './TagUIProvider';
import { TagCard } from './TagCard';
import { paths } from "@/shared/helpers/paths";

export function TagsPage({history, match}) {
  const tagUIEvents = {
    openQuestionListPage: (tagId) => {
      history.push({pathname: paths.SKILL_BANK.QUESTIONS, state: {tagId}});
    },
    openAssessmentListPage: (tagId) => {
      history.push({pathname: paths.ASSESSMENTS.BASE, state: {tagId}});
    }
  };

  return (
      <TagUIProvider tagUIEvents={tagUIEvents}>
        <TagCard/>
      </TagUIProvider>
  );
}
