import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import TagTableWrapper from './table/TagTableWrapper';

export function TagCard() {
  return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Tag List</h3>
            <h3 className="page-subhead subhead">From here you can view all the tags.</h3>
          </Col>
        </Row>
        <Row>
          <TagTableWrapper/>
        </Row>
      </Container>
  );
}
