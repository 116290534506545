import React, { createContext, useContext } from 'react';

const AssignmentUIContext = createContext();

export function useAssignmentUIContext() {
  return useContext(AssignmentUIContext);
}

export function AssignmentUIProvider({assignmentUIEvents, children}) {
  const value = {
    openViewAssignmentDialog: assignmentUIEvents.openViewAssignmentDialog,
    openNewAssignmentDialog: assignmentUIEvents.openNewAssignmentDialog,
    openEditAssignmentDialog: assignmentUIEvents.openEditAssignmentDialog,
    openDeleteAssignmentDialog: assignmentUIEvents.openDeleteAssignmentDialog,
  };

  return <AssignmentUIContext.Provider value={value}>{children}</AssignmentUIContext.Provider>;
}
