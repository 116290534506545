import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import AssignmentTable from './AssignmentTable';
import { useAssignmentUIContext } from '../AssignmentUIProvider';

const AssignmentTableWrapper = () => {
  const assignmentUIContext = useAssignmentUIContext();
  return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="products-list">
            <div className="card__title">
              <h5 className="bold-text">Assignments List</h5>
              <ButtonToolbar className="products-list__btn-toolbar-top">
                <Button color="primary" onClick={assignmentUIContext.openNewAssignmentDialog}>
                  Add new Assignments
                </Button>
              </ButtonToolbar>
            </div>
            <AssignmentTable/>
          </CardBody>
        </Card>
      </Col>
  );
};

export default AssignmentTableWrapper;
