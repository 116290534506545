import { paths } from '@/shared/helpers/paths';
import React, { Suspense } from 'react';
import Loading from '@/shared/components/Loading';
import { Redirect, Route, Switch } from 'react-router-dom';
import Announcements from './Announcements';
import Articles from './Articles';
import Events from './Events';
import Sponsorship from './Sponsorship';
import QA from './Q&A';
import Notifications from './Notifications';
import SpamRequests from './SpamRequests';
import DemoRequests from './DemoRequests';

export default function ManageHome() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {<Redirect exact from={paths.CADS_AI.HOME.BASE} to={paths.CADS_AI.HOME.ANNOUNCEMENT} />}
        <Route path={paths.CADS_AI.HOME.ANNOUNCEMENT} component={Announcements} />
        <Route path={paths.CADS_AI.HOME.NOTIFICATION} component={Notifications} />
        <Route path={paths.CADS_AI.HOME.ARTICLE} component={Articles} />
        <Route path={paths.CADS_AI.HOME.EVENTS} component={Events} />
        <Route path={paths.CADS_AI.HOME.SPONSORSHIP} component={Sponsorship} />
        <Route path={paths.CADS_AI.HOME.QA} component={QA} />
        <Route path={paths.CADS_AI.HOME.SPAM_REQUESTS} component={SpamRequests}/>
        <Route path={paths.CADS_AI.HOME.DEMO_REQUESTS} component={DemoRequests}/>
      </Switch>
    </Suspense>
  );
}
