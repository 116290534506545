import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { StepDefaultProps, StepPropTypes } from './Props';
import FormField from '@/shared/components/form/hook/FormField';
import { SelectField } from '@/shared/components/form/Select';
import { emptyStringPattern } from '@/shared/helpers';
import { extractFieldData, getStepFields } from '../index';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getAssessmentCategoryOptionsList,
  getAssessmentDataSkillOptions,
  getAssessmentTypeOptionsList,
} from '@/containers/Admin/Assessments/redux/actions';
import { getQuestionTagsList, } from '@/containers/Admin/SkillBank/Questions/redux/actions';
import { getCustomersListOptions } from '@/containers/Admin/Customers/redux/actions';
import { getDomainOptionsList } from '@/containers/Admin/SkillBank/Domains/redux/actions';
import { getSubCategoriesOptionsList } from '@/containers/Admin/SkillBank/SubCategories/redux/actions';
import { ASSESSMENT_TYPES } from '@/shared/helpers/constants';


const StepOne = ({ onSubmit, page, data }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm(extractFieldData(page, data));
  const [selectedAssessmentType, setSelectedAssessmentType] = React.useState(data?.assessmentType);
  React.useEffect(() => {
    dispatch(getAssessmentCategoryOptionsList());
    dispatch(getAssessmentTypeOptionsList());
    dispatch(getAssessmentDataSkillOptions());
    dispatch(getCustomersListOptions());
    dispatch(getDomainOptionsList());
    dispatch(getSubCategoriesOptionsList());
    dispatch(getQuestionTagsList());
  }, []);
  React.useEffect(() => {
    getStepFields(page).forEach((key) => {
      setValue(key, data[key]);
      if (key === 'assessmentType') {
        setSelectedAssessmentType(data[key]);
      }
    });
  }, [data]);

  // category options
  const categoryOptions = useSelector((state) => state.assessments.categoryOptions, shallowEqual);
  const [categoryOpts, setCategoryOpts] = React.useState([]);
  React.useEffect(() => {
    setCategoryOpts(categoryOptions?.map(({ id, title }) => ({ value: id, label: title })) ?? []);
  }, [categoryOptions]);

  // type optionsseSelector
  const typeOptions = useSelector((state) => state.assessments.typeOptions, shallowEqual);
  const [typeOpts, setTypeOpts] = React.useState([]);
  React.useEffect(() => {
    setTypeOpts(typeOptions?.map(({ id, title }) => ({ value: id, label: title })) ?? []);
  }, [typeOptions]);

  // data skill options
  const dataSkillOptions = useSelector((state) => state.assessments.dataSkillOptions, shallowEqual);
  const [dataSkillOpts, setDataSkillOpts] = React.useState([]);
  React.useEffect(() => {
    setDataSkillOpts(dataSkillOptions?.map(({ id, title }) => ({ value: id, label: title })) ?? []);
  }, [dataSkillOptions]);

  // customer options
  const customerOptions = useSelector((state) => state.customers.options, shallowEqual);
  const [customerOpts, setCustomerOpts] = React.useState([]);
  React.useEffect(() => {
    setCustomerOpts(customerOptions?.map(({ id, title }) => ({ value: id, label: title })) ?? []);
  }, [customerOptions]);

  // domains options
  const domainOptions = useSelector((state) => state.domains.options, shallowEqual);
  const [domainOpts, setDomainOpts] = React.useState([]);
  React.useEffect(() => {
    setDomainOpts(domainOptions?.map(({ id, title }) => ({ value: id, label: title })) ?? []);
  }, [domainOptions]);

  // subcategory options
  const subcategoryOptions = useSelector((state) => state.subCategories.options, shallowEqual);
  const [subcategoryOpts, setSubcategoryOpts] = React.useState([]);
  React.useEffect(() => {
    setSubcategoryOpts(
      subcategoryOptions?.map(({ id, title }) => ({ value: id, label: title })) ?? [],
    );
  }, [subcategoryOptions]);
  // tag options
  const {
    questionTags: tags,
  } = useSelector((state) => state.questions, shallowEqual);
  const [tagOptions, setTagOptions] = React.useState([]);
  React.useEffect(() => {
    setTagOptions(tags?.map((tag) => ({ value: tag.id, label: tag.name })) ?? []);
  }, [tags]);

  return (
    <form className="form wizard__form step-one" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__form-group">
        <span className="form__form-group-label">Name</span>
        <div className="form__form-group-field">
          <FormField
              name="title"
              control={control}
              component="input"
              errors={errors}
              rules={{
                required: 'This is required field',
                pattern: emptyStringPattern,
              }}
              defaultValue=""
              isAboveError={true}
              placeholder="A title for assessment"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Code</span>
        <div className="form__form-group-field">
          <FormField
              name="code"
              control={control}
              component="input"
              defaultValue=""
              placeholder="A code for assessment"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Description</span>
        <div className="form__form-group-field">
          <FormField
              name="description"
              control={control}
              component="textarea"
              errors={errors}
              rules={{
                required: 'This is required field',
              pattern: emptyStringPattern,
            }}
            defaultValue=""
            isAboveError={true}
            placeholder="What is this assessment about ?"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Assessment Category</span>
        <div className="form__form-group-field">
          <FormField
            control={control}
            name="assessmentCategory"
            component={SelectField}
            options={categoryOpts}
            placeholder="Select Assessment Category"
            rules={{ required: 'This is required field' }}
            errors={errors}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Assessment Type</span>
        <div className="form__form-group-field">
          <FormField
            control={control}
            onChange={(assessmentType) => {
              setSelectedAssessmentType(assessmentType);
              setValue('assessmentType', assessmentType);
            }}
            name="assessmentType"
            component={SelectField}
            options={typeOpts}
            placeholder="Select Assessment Type"
            rules={{ required: 'This is required field' }}
            errors={errors}
            isAboveError
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Data Skills</span>
        <div className="form__form-group-field">
          <FormField
              control={control}
              name="dataSkill"
              component={SelectField}
              options={dataSkillOpts}
              placeholder="Select data skill"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Customer</span>
        <div className="form__form-group-field">
          <FormField
            control={control}
            name="customer"
            component={SelectField}
            options={customerOpts}
            placeholder="Select Customer To Assign"
          />
        </div>
      </div>
      {[ASSESSMENT_TYPES.KNOWLEDGE_BASE_ASSESSMENT, ASSESSMENT_TYPES.DATA_LITERACY_ASSESSMENT].includes(selectedAssessmentType?.label)
          && (
              <>
                <div className="form__form-group">
                  <span className="form__form-group-label">Domain</span>
                  <div className="form__form-group-field">
                    <FormField
                        control={control}
                        name="domain"
                        component={SelectField}
                        options={domainOpts}
                        placeholder="Select Domain"
                    />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Subcategory</span>
            <div className="form__form-group-field">
              <FormField
                control={control}
                name="subcategory"
                component={SelectField}
                options={subcategoryOpts}
                placeholder="Select Subcategory"
              />
            </div>
          </div>
        </>
      )}
       <div className="form__form-group">
        <span className="form__form-group-label">Tags</span>
        <div className="form__form-group-field">
          <FormField
            control={control}
            name="tags"
            component={SelectField}
            isMulti
            options={tagOptions}
            placeholder="Select tag"
          />
        </div>
      </div>
      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button color="primary" type="button" disabled className="previous">
          Back
        </Button>
        <Button color="primary" type="submit" className="next">
          Next
        </Button>
      </ButtonToolbar>
    </form>
  );
};

StepOne.propTypes = StepPropTypes;
StepOne.defaultProps = StepDefaultProps;

export default StepOne;
