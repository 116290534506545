import React, { useRef } from 'react';
import { Button } from 'reactstrap';
import Select from 'react-select';
import TableHead from '@material-ui/core/TableHead';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setAssessmentFilters } from '../../redux/actions';
import { getCustomersListOptions } from '@/containers/Admin/Customers/redux/actions';
import { getQuestionTagsList } from "@/containers/Admin/SkillBank/Questions/redux/actions";
import { useLocation } from "react-router-dom";

export function AssessmentTableSearchBar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const {tagId: tagIdFromLocation} = location?.state ?? {};
  const [tagId, setTagId] = React.useState(null);
  const totalAssessmentCount = useSelector(
      (state) => state.assessments?.data?.length,
      shallowEqual,
  );

  const [isSearched, setIsSearched] = React.useState(false);

  const [searchStatus, setSearchStatus] = React.useState({value: null, label: 'All'});

  const customerOriginalOpts = useSelector((state) => state.customers?.options, shallowEqual);
  const [customerOptions, setCustomerOptions] = React.useState({value: null, label: 'All'});
  const [selectedCustomer, setSelectedCustomer] = React.useState({ value: null, label: 'All' });
  React.useEffect(() => {
    dispatch(getCustomersListOptions());
  }, []);
  React.useEffect(() => {
    setCustomerOptions([
      {value: null, label: 'All'}, //adding default select all option
      ...(customerOriginalOpts?.map((customer) => ({
        value: customer.id,
        label: customer.title,
      })) ?? []),
    ]);
  }, [customerOriginalOpts]);

  const {questionTags: tags} = useSelector((state) => state.questions, shallowEqual);
  const [tagOptions, setTagOptions] = React.useState({value: null, label: 'All'});
  const [selectedTags, setSelectedTags] = React.useState([]);
  React.useEffect(() => {
    dispatch(getQuestionTagsList());
  }, []);
  React.useEffect(() => {
    if (tagIdFromLocation) {
      setTagId(tagIdFromLocation);
    }
  }, [tagIdFromLocation]);
  React.useEffect(() => {
    const options = tags?.map((tag) => ({value: tag.id, label: tag.name}));
    setTagOptions(options ?? []);
    if (tagId) {
      setSelectedTags(options?.filter(tag => tag.value === tagId));
    }
  }, [tags, tagId]);


  const searchNameRef = useRef(null);
  const handleSearch = () => {
    setIsSearched(true);
    dispatch(
        setAssessmentFilters({
          search: searchNameRef?.current?.value,
          status: searchStatus.value,
          pageNumber: 1,
          organizationId: selectedCustomer.value,
          tags: selectedTags.map(tag => tag.value).join(',')
      }),
    );
  };

  const handleClear = () => {
    if (searchNameRef?.current?.value) searchNameRef.current.value = '';
    setIsSearched(false);
    setSelectedTags([]);
    setTagId(null);
    setSelectedCustomer({value: null, label: 'All'});
    dispatch(setAssessmentFilters({search: '', status: null, organizationId: '', tags: ''}));
  };

  return (
    <TableHead>
      <div className="table__search">
        <div className="table__search_item">
          <b>Assessment Title</b>
          <div className="form">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon" style={{ height: 37 }}>
                  <MagnifyIcon />
                </div>
                <input
                  ref={searchNameRef}
                  className="search-bar-search-text"
                  name="searchText"
                  type="text"
                  placeholder="Search by Assessment Title"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table__search_item">
          <b>Customer</b>
          <div style={{ minWidth: 300 }}>
            <Select
                name="customer"
                value={selectedCustomer}
                onChange={setSelectedCustomer}
                autosize={true}
                options={customerOptions}
                clearable={false}
                className="react-select"
                placeholder={'Select Customer'}
                classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item">
          <b>Tags</b>
          <div style={{minWidth: 300}}>
            <Select
                name="customer"
                value={selectedTags}
                onChange={setSelectedTags}
                autosize={true}
                options={tagOptions}
                isMulti={true}
                isClearable={true}
                className="react-select"
                placeholder={'Select Tags'}
                classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item">
          <b>Status</b>
          <div style={{minWidth: 200}}>
            <Select
                name="status"
                value={searchStatus}
                onChange={setSearchStatus}
              autosize={true}
              options={[
                { value: null, label: 'All' },
                { value: true, label: 'Published' },
                { value: false, label: 'Draft' },
              ]}
              clearable={false}
              className="react-select"
              placeholder={'Select Status'}
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="table__search_item mt-3">
          <Button color="primary" onClick={handleSearch}>
            {' '}
            Search{' '}
          </Button>
        </div>
        <div className="table__search_item mt-3">
          <Button color="secondary" onClick={handleClear} outline>
            Clear
          </Button>
        </div>
      </div>

      {isSearched && (
        <div className="ml-1">
          <span>Found {totalAssessmentCount} matches</span>
        </div>
      )}
    </TableHead>
  );
}
