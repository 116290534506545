import { sendRequest } from '../base/ApiService';
import { httpMethod } from '@/shared/helpers/httpMethod.enum';

const assessmentEndpoint = process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_ADMIN_ENDPOINT;

const tagsEndpoints = {
  GET_TAG_LIST: `${assessmentEndpoint}/getAllTagListByFilter`,
}

const getTagList = (filters) => {
  // use mockGetTagList() for mock data
  return sendRequest({
    fullUrl: tagsEndpoints.GET_TAG_LIST,
    method: httpMethod.GET,
    params: filters
  })
};


export default {
  getTagList,
};


const mockGetTagList = () =>
    new Promise((resolve, reject) => {
      setTimeout(
          () =>
              resolve({
                isSuccess: true,
                data: {
                  status: 200,
                  messages: [''],
                  data: {
                    totalRecords: 7,
                    currentPage: 1,
                    pageSize: 10,
                    records: [
                      {"id": 4, "name": "mock-string"},
                      {"id": 9, "name": "mock-tag 21"},
                      {"id": 13, "name": "mock-tonyTag"},
                      {"id": 1, "name": "mock-string11234"},
                      {"id": 12, "name": "mock-tagEdit12"},
                      {"id": 11, "name": "mock-tag11"},
                      {"id": 14, "name": "mock-TonyNewtag"}]
                  },
                },
              }),
          500,
      );
    });
