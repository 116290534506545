import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/shared/components/Loading';
import { paths } from '@/shared/helpers/paths';
import { AssignmentsPage } from './components/AssignmentPage';

export const assignmentsBaseUrl = paths.ASSIGNMENTS.BASE;

export default function Assignments() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={assignmentsBaseUrl} component={AssignmentsPage}/>
      </Switch>
    </Suspense>
  );
}
