import { httpMethod } from "@/shared/helpers/httpMethod.enum";
import { sendRequest } from "@/utils/api/base/ApiService";

const CADS_API_ENDPOINT = process.env.REACT_APP_CADS_AI_API + process.env.REACT_APP_ADMIN_ENDPOINT
const assignmentsEndpoints = {
  ADD_ASSIGNMENT: `${CADS_API_ENDPOINT}/assignAssessmentToOrganization`,
  GET_ASSIGNMENT_BY_ID: `${CADS_API_ENDPOINT}/GetAssessmentAssignmentById`,
  EDIT_ASSIGNMENT: '/editAssignment',
  GET_ASSIGNMENT: '/getAssignment',
  GET_ASSIGNMENT_LIST: `${CADS_API_ENDPOINT}/getAssessmentAssignmentList`,
};


const addAssignment = (assignmentData) => {
  return sendRequest({
    fullUrl: assignmentsEndpoints.ADD_ASSIGNMENT,
    method: httpMethod.POST,
    data: assignmentData,
  });
};

const editAssignment = (assignmentData) => {
  return addAssignment(assignmentData);
};


const getAssignmentById = (assignmentId) => {
  // return mockGetAssignmentById();
  return sendRequest({
    fullUrl: assignmentsEndpoints.GET_ASSIGNMENT_BY_ID,
    method: httpMethod.GET,
    params: {id: assignmentId},
  });
};

const getAssignmentList = (filter) => {
  // return mockGetAssignmentList();
  return sendRequest({
    fullUrl: assignmentsEndpoints.GET_ASSIGNMENT_LIST,
    method: httpMethod.GET,
    params: filter,
  });
};


export default {
  addAssignment,
  editAssignment,
  getAssignmentById,
  getAssignmentList,
};

const mockGetAssignmentList = (filters) =>
    new Promise((resolve, reject) => {
      setTimeout(
          () =>
              resolve({
                isSuccess: true,
                status: 200,
                messages: [''],
                data: {
                  currentPage: 1,
                  pageSize: 10,
                  totalRecords: 12,
                  data: [
                    {id: 1, organizationName: 'Mock Org 1', assessmentName: 'Mock Assessment 1', expiryDate: '2023-02-01', createDate: '2022-02-01'},
                    {id: 2, organizationName: 'Mock Org 2', assessmentName: 'Mock Assessment 2', expiryDate: '2023-02-01', createDate: '2022-02-02'},
                    {id: 3, organizationName: 'Mock Org 3', assessmentName: 'Mock Assessment 3', expiryDate: '2023-02-01', createDate: '2022-02-03'},
                  ],
                },
              }),
          500,
      );
    });

const mockGetAssignmentById = () => {
  return new Promise((resolve => {
    setTimeout(() => {
      resolve({
        isSuccess: true,
        status: 200,
        messages: [''],
        data: {
          data: {
            id: 1,
            organization: {name: 'Mock Org 1'},
            assessment: {name: 'Mock Assessment 1'},
            expiryDate: '2023-02-01',
            createDate: '2022-02-01'
          },
        },
      })
    }, 500);
  }));
}
